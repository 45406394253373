<template>
  <div class="chat__body" id="chat__body">
    <chat-message v-for="(msg, index) in msgs" :key="index" :msg="msg" :userData="userData"
      :prev="[index == 0 ? null : msgs[index - 1]]">
    </chat-message>
  </div>
</template>

<script>
import ChatMessage from "@/components/ChatMessage";

export default {
  components: {
    ChatMessage
  },
  props: ["msgs", "userData", "dataAdvisor"],
};
</script>

<style lang="scss">
.chat__body {
  padding: 20px;
  overflow: scroll;
  width: 100%;
  height: calc(100vh - 142px);
  text-align: center;

  &__hello {
    max-width: 600px;
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    border: 2px #00afb9 solid;
    padding: 10px 20px;
    overflow: hidden;
    border-radius: 26px;
    margin-top: 100px;
    margin-bottom: 100px;

    background: linear-gradient(rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), url(/chat/images/background_hello.jpeg) no-repeat;
    background-size: 100%;

    &__image {
      max-width: 300px;
      text-align: center;
      margin: 0 auto;

      lottie-player {
        width: 100%;
        margin-top: -100px;
      }
    }

    &__title {
      font-size: 26px;
      font-weight: 400;
      margin-top: -120px;
      color: #00afb9;
      margin-bottom: 20px;
    }
  }
}

.chat__body::-webkit-scrollbar {
  display: none;
}
</style>
