<template>
  <div class="not_found">
      <h1>404</h1><br/>
      <h2>PAGE NOT FOUND</h2>
      <a href="/">Go home</a>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>

<style>
.not_found {
    padding: 40px 20px;
    text-align: center;
}
h1 {
    font-size: 80px!important;
    font-weight: 700!important;
}
</style>
