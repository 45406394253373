<template>
  <div class="login">
    <div id="fb-root"></div>
    <div class="login__left">
      <img src="/images/image_login.svg" />
    </div>
    <client-only>
      <div class="login__right">
        <div class="login__right__block">
          <div class="login__right__form">
            <div class="login__right__form__logo">
              <nuxt-link :to="'/'">
                <div class="login__right__form__logo__block">
                  <img src="../assets/images/frame.png" />
                </div>
                <p>WhiteG</p>
              </nuxt-link>
            </div>

            <div class="login__right__form__username">
              <h2>Hello Again!</h2>

              <div class="login__right__form__username__alert" v-if="message.status && message.text">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                  {{ message.text }}
                  <button type="button" class="btn close" data-dismiss="alert" aria-label="Close"
                    v-on:click="handleCloseMessage">
                    <span aria-hidden="true" style="font-size: 30px; color: ">&times;</span>
                  </button>
                </div>
              </div>

              <div class="login_with_social">
                <div class="login_with_google">
                  <div id="taki_login_google" class="g_id_signin" data-type="standard" data-size="large"
                    data-theme="filled_blue" data-ux_mode="popup" data-text="sign_in_with" data-shape="rectangular"
                    data-logo_alignment="left" :data-width="dataWidth" style="width: 100% !important; float: left">
                  </div>
                </div>
                <div class="login_with_facebook" v-on:click="handleLoginFacebook">
                  <div class="login_with_facebook__svg">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                      <path
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                    </svg>
                  </div>
                  <p>Login with Facebook</p>
                </div>

              </div>

              <div class="pt-3 mb-3 w-100" style="float: left">
                <p style="text-align: center; width: 100%">
                  Don't have an account?<nuxt-link :to="'/signup'">Sign up now
                  </nuxt-link>
                  {{ ". If you have an account by e-mail and password, click" }}
                  <a href="#" v-on:click="
                    () => {
                      isLoginMail = !isLoginMail;
                    }
                  ">here</a>
                  to login.
                </p>

              </div>

              <div style="width: 100%; float: left" v-if="isLoginMail">
                <div class="form-group">
                  <label class="pt-4 pb-2" for="exampleInputEmail1">Token</label>
                  <input type="email" class="form-control form-control-lg" id="exampleInputEmail1"
                    aria-describedby="emailHelp" placeholder="Enter token" style="font-size: 16px"
                    v-model.trim="userName" @keyup.enter="joinSubmit" required :disabled="isLoading" />
                </div>

                <div class="form-group form-check pt-3 pb-2">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="rememberPassword"
                    :disabled="isLoading" />
                  <label class="form-check-label" for="exampleCheck1">Remember for 30 days</label>
                </div>
                <button type="submit" class="btn btn-primary w-100" @click="joinSubmit" v-if="!isLoading">Login</button>

                <button type="submit" class="btn btn-primary w-100" v-if="isLoading" disabled>
                  <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                  Login...
                </button>
              </div>
            </div>

            <div id="g_id_onload"
              data-client_id="1010938218852-k595iror2jvjkl53unq0407p14pajhkv.apps.googleusercontent.com"
              :data-login_uri="mainUrl + '/google-login'" data-cancel_on_tap_outside="false" data-skip_prompt_cookie="sid"
              v-if="isShowPopup"></div>
          </div>
        </div>
      </div>
      <div class="modal-body__loading" v-if="isLoading">
        <div class="spinner-border text-secondary" role="status"></div>
      </div>
    </client-only>
  </div>
</template>

<script>

import { loginService } from "../services/login.services"

export default {
  data() {
    return {
      userName: "",
      rememberPassword: false,
      message: {
        status: false,
        text: "",
      },
      isLoading: false,
      password: "",
      formState: {
        username: "",
        password: "",
        remember: true,
      },
      gapi: null,
      isShowPopup: false,
      dataWidth: 400,
      isShowPassword: false,
      idToken: "",
      permissionGranted: false,
      loading: false,
      isLoginMail: false,
      mainUrl: process.env.VUE_APP_URL_MAIN,
    };
  },
  props: ["dataUser"],
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "https://accounts.google.com/gsi/client");
    recaptchaScript.setAttribute("async", "");
    recaptchaScript.setAttribute("defer", "");
    document.head.appendChild(recaptchaScript);

    let appleScript = document.createElement("script");
    appleScript.setAttribute(
      "src",
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
    );
    document.head.appendChild(appleScript);

    let facebookScript = document.createElement("script");
    facebookScript.setAttribute(
      "src",
      "https://connect.facebook.net/vi_VN/sdk.js#xfbml=1&version=v14.0&appId=1153021275276469&autoLogAppEvents=1"
    );
    facebookScript.setAttribute("crossorigin", "anonymous");
    facebookScript.setAttribute("defer", "");
    facebookScript.setAttribute("async", "");
    facebookScript.setAttribute("nonce", "BO3Ua1T2");
    document.head.appendChild(facebookScript);
  },

  created() {
    if (process.client) {
      this.isShowPopup = true;
      if (this.userObject) {
        window.location.href = "/";
      }
      this.handleRequestPermission();
      this.handleGetIdToken();
      let currentWidth = window.innerWidth;

      if (currentWidth > 500) {
        if (currentWidth <= 1080) {
          let rightWidth = currentWidth / 2;
          this.dataWidth = rightWidth - 40;
        } else {
          this.dataWidth = 400;
        }
      } else {
        this.dataWidth = currentWidth - 40;
      }

      document.addEventListener("AppleIDSignInOnSuccess", (event) => {
        // Handle successful response.
        console.log(event.detail.data);
      });

      // Listen for authorization failures.
      document.addEventListener("AppleIDSignInOnFailure", (event) => {
        // Handle error.
        console.log(event.detail.error);
      });
    }
   

    if (this.$route.query.credential) {
      this.isShowPopup = false;
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("user_token", this.$route.query.credential);
      params.append("device_uuid", "68413da2d51809da");
      params.append("device_type", "website");

      this.isLoading = true;
      let self = this;
      setTimeout(() => {
        params.append("device_signature", self.idToken);
        loginService
          .loginWithGoogle(params)
          .then((response) => {
            if (response && response.data) {
              let dataToUpdate = response.data;
              dataToUpdate = {
                ...dataToUpdate,
                ...{ auth_code: response?.headers["x-authorization"] },
                ...{ device_signature: self.idToken },
              };
              localStorage.setItem("user", JSON.stringify(dataToUpdate));
              self.$store.commit("reloadUser", true);
              window.location.href = "/";
            } else {
              self.message = {
                status: true,
                text: "Have an error. Please try again! ",
              };
            }
            self.isLoading = false;
          })
          .catch((error) => {
            self.isLoading = false;
            self.message = {
              status: true,
              text: error.response.data.message,
            };
          });
      }, 1000);
    } else {
      this.isShowPopup = true;
    }

    if (this.dataUser && this.dataUser.apple_code) {
      this.isLoading = true;
      this.isShowPopup = false;
      const params = new URLSearchParams();
      params.append("identityToken", this.dataUser.apple_id_token);
      params.append("device_uuid", "68413da2d51809da");
      params.append("device_type", "website");

      this.isLoading = true;
      let self = this;
      setTimeout(() => {
        params.append("device_signature", self.idToken);
        loginService
          .loginWithApple(params)
          .then((response) => {
            if (response && response.data) {
              let dataToUpdate = response.data;
              dataToUpdate = {
                ...dataToUpdate,
                ...{ auth_code: response?.headers["x-authorization"] },
                ...{ device_signature: self.idToken },
              };
              localStorage.setItem("user", JSON.stringify(dataToUpdate));
              self.$store.commit("reloadUser", true);
              window.location.href = "/";
            } else {
              self.message = {
                status: true,
                text: "Have an error. Please try again! ",
              };
            }
            self.isLoading = false;
          })
          .catch((error) => {
            self.isLoading = false;
            self.message = {
              status: true,
              text: error.response.data.message,
            };
          });
      }, 1000);
    } else {
      this.isShowPopup = true;
    }
  },
  watch: {
    gapi(newData) { },
  },
  computed: {
    userObject() {
      return this.$store.state.userObject;
    },
    userToken() {
      return this.$store.state.userToken;
    },
  },
  methods: {

    async handleRequestPermission() {
      const permission = await Notification.requestPermission();
      this.permissionGranted = permission === "granted";
    },
    async handleGetIdToken() {
      try {
        this.loading = true;
        let currentToken;
        try {
          currentToken = await this.$fire.messaging.getToken();
        } catch (error) {
          this.idToken = "";
          this.loading = false;
        }
        if (currentToken) {
          this.idToken = currentToken;
        } else {
          this.idToken = "";
        }
        this.loading = false;
      } catch (error) {
        this.idToken = "";
        this.loading = false;
      }
    },
    handleLoginFacebook() {
      let self = this;
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            let accessToken = response.authResponse.accessToken;
            self.isShowPopup = false;
            self.isLoading = true;
            const params = new URLSearchParams();
            params.append("user_token", accessToken);
            params.append("device_uuid", "68413da2d51809da");
            params.append("device_type", "website");
            params.append("device_signature", self.idToken);

            self.isLoading = true;
            loginService
              .loginWithFacebook(params)
              .then((response) => {
                if (response && response.data) {
                  let dataToUpdate = response.data;
                  dataToUpdate = {
                    ...dataToUpdate,
                    ...{ auth_code: response?.headers["x-authorization"] },
                    ...{ device_signature: self.idToken },
                  };
                  localStorage.setItem("user", JSON.stringify(dataToUpdate));
                  self.$store.commit("reloadUser", true);
                  window.location.href = "/";
                } else {
                  self.message = {
                    status: true,
                    text: "Have an error. Please try again! ",
                  };
                }
                self.isLoading = false;
              })
              .catch((error) => {
                self.isLoading = false;
                self.message = {
                  status: true,
                  text: error.response.data.message,
                };
              });
            //Process API Facebook
          }
        },
        { scope: "public_profile,email" }
      );
    },
    handleCloseMessage() {
      this.message = {
        status: false,
        text: "",
      };
    },
    joinSubmit() {
      this.handleCloseMessage();
      if (!this.userName) {
        this.message = {
          status: true,
          text: "Please fill out Token!",
        };
        return false;
      }

      const params = new URLSearchParams();
      params.append("user_token", this.userName);
      params.append("device_uuid", "67675.khjHGJJ.87687.dsggdw");
      params.append("device_signature", this.idToken);
      params.append("device_type", "website");

      this.isLoading = true;
      loginService
        .loginWithGoogle(params)
        .then((response) => {
          if (response && response.data) {
            let dataToUpdate = response.data;
            dataToUpdate = {
              ...dataToUpdate,
              ...{ auth_code: response?.headers["x-authorization"] },
              ...{ device_signature: this.idToken },
            };
            localStorage.setItem("user", JSON.stringify(dataToUpdate));
            this.$store.commit("reloadUser", true);
            window.location.href = "/";
          } else {
            this.message = {
              status: true,
              text: "Token is not correct. Please try again! ",
            };
            this.isShowPopup = true;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.message = {
            status: true,
            text: "Token is not match!",
          };

          this.isShowPopup = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.login {
  width: 100%;
  float: left;

  &__left {
    width: 50%;
    right: 50%;
    top: 0;
    bottom: 0;
    position: fixed;
    background-color: #e0e0e0;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 40px;
    overflow: hidden;

    @include breakpoint(mobile) {
      display: none;
    }

    img {
      max-width: 400px;
      width: 80%;
      margin: 0 auto;
    }
  }

  &__right {
    width: 50%;
    float: left;
    padding-bottom: 50px;
    overflow-y: auto;
    margin-left: 50%;

    &__block {
      overflow-y: auto;
      width: 100%;
      float: left;
    }

    @include breakpoint(mobile) {
      width: 100%;
      margin-left: 0;
    }

    .login_with_facebook {
      width: 100%;
      background: #fff;
      border-radius: 6px;
      margin-top: 10px;
      float: left;
      border: 1px #3578e5 solid;
      transition: 500ms all;
      cursor: pointer;
      user-select: none;

      &__svg {
        width: 38px;
        float: left;
        border-radius: 4px 0 0 4px;
        background: #3578e5;
        height: 39px;
        margin-top: -1px;

        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
          margin: 9px;
        }
      }

      p {
        width: calc(100% - 38px);
        float: left;
        font-weight: 700;
        font-size: 14px;
        color: #3578e5;
        line-height: 38px;
        text-align: center;
        padding: 0 10px;
        margin-bottom: 0;
      }

      &:hover {
        background: #f0f0f0;
      }
    }

    .login_with_email {
      width: 100%;
      background: #fff;
      border-radius: 6px;
      margin-top: 10px;
      float: left;
      border: 1px $primaryColor solid;
      transition: 500ms all;
      cursor: pointer;
      user-select: none;

      &__svg {
        width: 38px;
        float: left;
        border-radius: 4px 0 0 4px;
        background: $primaryColor;
        height: 39px;
        margin-top: -1px;

        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
          margin: 9px;
        }
      }

      p {
        width: calc(100% - 38px);
        float: left;
        font-weight: 700;
        font-size: 14px;
        color: $primaryColor;
        line-height: 38px;
        text-align: center;
        padding: 0 10px;
        margin-bottom: 0;
      }

      &:hover {
        background: #f0f0f0;
      }
    }

    .login_with_google {
      width: 100%;
      float: left;
      overflow: hidden;
      border-radius: 6px;
      cursor: pointer;
      user-select: none;

      span {
        font-size: 14px;
        font-family: $fontFamily;
        font-weight: 700;
      }
    }

    .login_with_apple {
      width: 100%;
      float: left;
      position: relative;
      height: 40px;
      border-radius: 6px;
      border: 1px #000 solid;
      overflow: hidden;
      margin-top: 10px;

      &__svg {
        width: 38px;
        height: 38px;
        background-color: #000;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;

        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
          margin: 9px;
        }
      }

      .signin-button {
        width: 100%;
        float: left;
        margin-left: 17px;
      }
    }

    &__form {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      &__logo {
        width: 200px;
        float: left;
        margin: 100px 0 10px 0;

        p {
          font-weight: 900;
          font-size: 18px;
          color: $primaryColor;
          line-height: 50px;
          float: left;
          margin-left: 8px;
        }

        &__block {
          // width: 50px;
          height: 50px;
          float: left;
          // background-color: $primaryColor;
          // border-radius: $borderRadiusPrimary;

          img {
            height: 40px;
            width: auto;
            margin: 5px 14px;
          }
        }
      }

      &__username {
        margin-top: 10px;
        max-width: 400px;
        border-top: 1px $borderColor solid;
        padding-top: 30px;
        width: 100%;

        .alert {
          button {
            position: absolute;
            top: 4px;
            right: 4px;
          }
        }
      }

      &__username__label {
        display: block;
        text-align: center;
        font-weight: 700;
        color: #292929;
      }

      h2 {
        font-family: "Montserrat", sans-serif;
        font-weight: 700 !important;
      }

      &__avatar {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        object-fit: cover;

        &__overlay {
          position: absolute;
          background-color: #000;

          top: 0;
          bottom: 0;
          left: 0;
          right: 0;

          opacity: 0;
          height: 100px;
          width: 100px;
          border-radius: 50%;
          transition: 0.3s ease;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      &__label {
        &:hover {
          .login__right__form__avatar__overlay {
            opacity: 0.2;
          }
        }
      }

      .login_with_social {
        width: 100%;
        float: left;
        margin-top: 50px;

        &__text {
          width: 100%;
          margin-top: 30px;
          margin-bottom: 40px;
          text-align: center;
          border-bottom: 1px $borderColor solid;
          height: 15px;

          &__heading {
            width: 160px;
            background: #fff;
            margin: 0 auto;
            height: 30px;
            font-size: 14px;
            font-weight: 700;
            padding: 0 20px;
            line-height: 30px;
          }
        }
      }
    }
  }

  .login__form__label .login__form .login__form .login__form__username__input {
    width: 100%;
    margin-top: 2rem;
    padding: 0.4rem;
    border: none;
    border-bottom: 1px solid #afb4b8;
    text-align: center;
    font-size: 18px;
  }
}

.form-group {
  position: relative;
}

.form-group-show {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 56px;
  cursor: pointer;
  right: 0;
  user-select: none;

  &.active {
    svg {
      fill: $primaryColor;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #aaa;
  }
}

.modal-body {
  &__loading {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;

    .spinner-border {
      margin: 0 auto;
      margin-top: calc(50vh - 50px);
      margin-bottom: 100px;
      color: #fff !important;
    }
  }
}

.login__right__form__username__alert {
  width: 100%;
  float: left;
  margin-bottom: -40px;
  margin-top: 10px;

  button {
    margin-right: 10px;
  }
}
</style>
