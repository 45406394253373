<template>
  <div class="list_body">
    <div v-for="(item, index) in dataRoom" :key="'chat_value_' + index" :class="
      item.chat_room_id._id === chatIdActive
        ? 'chat_item success_active'
        : 'chat_item'
    ">
      <!-- <div class="chat_detail__heading__body">{{ dataSubscription }}</div> -->

      <router-link
        :to="{ name: 'chat', params: { id: (item.chat_room_id._id) ? item.chat_room_id._id : item.chat_room_id.chat_room_id } }">
        <!-- <div class="chat_item__avatar" v-if="item.partner_id.user_avatar">
          <img :src="
            item && item.partner_id.user_avatar
              ? item.partner_id.user_avatar
              : 'https://i.stack.imgur.com/l60Hf.png'
          " @error="replaceByDefault" />
        </div> -->


        <div class="chat_item__left">
          <div class="chat_item__left__title">
            <!-- <div>{{ handleGetSubscription(item.partner_id._id) }}</div> -->

            <div class="chat_item__left__title__name"
              :style="item.read_count ? { color: '#00afb9' } : { color: '#aaa', fontWeight: '300' }">
              {{ item.partner_id.display_name }}
            </div>
            <div class="chat_item__left__title__time"
              v-if="isToDay(momentNew(item.chat_room_id.updatedAt, 'DD-MM-YYYY'))">
              {{ momentNew(item.chat_room_id.updatedAt, "hh:mm:ss") }}
            </div>
            <div class="chat_item__left__title__time"
              v-if="!isToDay(momentNew(item.chat_room_id.updatedAt, 'DD-MM-YYYY'))">
              {{ momentNew(item.chat_room_id.updatedAt, "DD/MM/YYYY") }}
            </div>
            <!-- <di v class="chat_item__left__description" :style="item.read_count ? { color: '#00afb9' } : { color: '#888' }">
            <p>{{ truncateString(item.chat_room_id.last_message, 20, '....') }}</p>
            <div type=" button" class="button_expired" v-if="handleCheckTime(item.chat_room_id.end_time)">
              Expired
            </div>
            <div type="button" class="button_expired" style="margin-right: 8px " v-if="item.user_permission === 'read'">
              Read Only
            </div>
          </di> -->
            <div class="chat_item__left__title__read" v-if="item.read_count">
              {{ item.read_count }}
            </div>

            <!-- <div class="chat_item__left__title__time" v-if="dataSubscription && dataSubscription.length">Plan: {{
                dataSubscription[0].plan_id.name
            }}</div> -->

          </div>

        </div>
      </router-link>
      <router-view />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
export default {
  props: {
    dataList: Array,
    chatIdActive: String,
    isReloadList: Number,
    userData: Object,
  },
  data() {
    let currentTime = new Date().getTime();
    return {
      currentTime: currentTime,
      dataRoom: [],
      dataSubscription: []
    };
  },

  created() { },
  watch: {
    dataList(newData) {
      let dataReturn = newData.filter((item, index) => {
        if (item?.chat_room_id) {
          return true;
        } else {
          return false;
        }
      })
      this.dataRoom = dataReturn;
      // this.handleGetSubscription(dataReturn);
    }
  },

  methods: {
    replaceByDefault(e) {
      e.target.src = '/chat/images/user_default.png';
    },
    isToDay(date) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;
      return date === today;
    },
    truncateString(string, length, ending) {
      if (length == null) {
        length = 100;
      }
      if (ending == null) {
        ending = '...';
      }
      if (string.length > length) {
        return string.substring(0, length - ending.length) + ending;
      } else {
        return string;
      }
    },
    handleCheckTime(stopTime) {
      let stopTimeNumber = new Date(stopTime).getTime();
      if (stopTimeNumber - this.currentTime <= 0) {
        return true;
      } else {
        return false;
      }
    },
    momentNew(dateTime, format) {
      return moment(dateTime).format(format);
    },

    async handleGetSubscription(userID) {

      let self = this;
      const config = {
        headers: {
          "X-Authorization": this.userData?.auth_code,
        },
      };

      let urlUser =
        process.env.VUE_APP_CHAT_URL +
        "/subscribe/list-subscribe?user_id=" + userID;

      let newData = await axios
        .get(urlUser, config)
        .then((response) => {
          if (response && response.data) {
            // console.log(response.data)

            let data = response.data;
            if (data && data.length) {

              //this.dataSubscription = data;
              return data;
            }
            else {
              return []
              this.dataSubscription = [];
            }
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
          // this.dataSubscription = [];
          return error;

        });

      return newData;

    },

  },
};
</script>

<style lang="scss" scoped>
.chat_item {
  width: 100%;
  float: left;
  border-bottom: 1px #f5f5f5 solid;
  padding: 16px 0;
  user-select: none;
}

.chat_item.success_active {
  background: #efefef;
  width: 359px;
  margin-left: -16px;
  margin-right: -15px;
  padding-left: 16px;
  padding-right: 15px;
}

.background-border {}

.chat_choose_avatar {
  width: 30px;
  height: 30px;
  float: left;
}

.chat_choose_avatar img {
  width: 30px;
  height: auto;
  object-fit: cover;
  margin-top: 2px;
}

.chat_choose__left {
  width: calc(100% - 56px);
  padding: 0 0 0 16px;
  float: left;
}

.chat_item__avatar {
  width: 50px;
  height: 50px;
  float: left;
  position: relative;
  border-radius: 50%;
  margin-bottom: 20px;
}

.button_expired {
  width: 80px;
  margin-top: -8px;
  float: left;
  border: 1px #f08080 solid;
  color: #f08080;
  font-size: 11px;
  font-weight: 700;
  border-radius: 4px !important;
  text-align: center;
}

.chat_item__online {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00afb9;
  border: 4px #fff solid;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.chat_item__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.chat_item__left {
  width: calc(100% - 60px);
  padding: 0 0 0 16px;
  float: left;
}

.chat_item__left__title {
  width: 100%;
  float: left;
}

.chat_item__left__title__name {
  width: 50%;
  float: left;
  color: #444;
  font-size: 15px;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chat_item__left__title__time {
  width: 40%;
  float: left;
  font-size: 13px;
  color: #aaa;
  text-align: right;
}

// .chat_item__left__description {
//   width: 60%;
//   color: #888;
//   font-size: 14px;
//   // font-weight: 400;
//   margin-top: 8px;
//   float: left;
//   word-wrap: break-word;

//   p {
//     width: 100%;
//     float: left;
//   }
// }

.chat_item__left__title__read {
  width: 10%;
  float: left;
  // font-size: 14px;
  color: #00afb9;
  text-align: right;
  font-weight: 700;

  // color: #efefef;
  // background-color: #00afb9;
  // border-radius: 80%;
  // // width: 30px;
  // width: 40%;
  // height: 20px;
  // justify-content: center;
  // align-items: center;
  // text-align: center;
}

.chat__body {
  overflow: scroll;
  width: 100%;
}

.list_body {
  padding: 16px;
  float: left;
  margin-bottom: 20px;
  width: 100%;
}

h2 {
  padding: 0;
  margin: 0;
}

.chat__body::-webkit-scrollbar {
  display: none;
}
</style>
