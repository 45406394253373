import axios from "axios";

export const axiosPostInstanceBackend = (url, data, config) => {
    return axios.post(url, data, {
        ...config,
    });
};

export const axiosPutInstanceBackend = (url, data, config) => {
  return axios.put(url, data, {
      ...config,
  });
};

export const axiosDeleteInstanceBackend = (url, data, config) => {
  return axios.delete(url, {
      ...config,
  });
};

export const axiosPatchInstanceBackend = (url, data, config) => {
  return axios.patch(url, data, {
      ...config,
  });
};

export const axiosGetInstanceBackend = (url, config) => {
  return axios.get(url, {
      ...config,
  });
};

export const axiosInstance = axios.create({
    baseURL: process.env.NEW_BACKEND_URL,
    withCredentials: true,
    responseType: "json",
    timeout: 5000,
});

axiosInstance.interceptors.request.use(
    function(config) {
        // Do something before request is sent
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
    function(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function(error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    },
);
