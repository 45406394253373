import { render, staticRenderFns } from "./ChatGroup.vue?vue&type=template&id=119b9180&scoped=true&"
import script from "./ChatGroup.vue?vue&type=script&lang=js&"
export * from "./ChatGroup.vue?vue&type=script&lang=js&"
import style0 from "./ChatGroup.vue?vue&type=style&index=0&id=119b9180&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119b9180",
  null
  
)

export default component.exports