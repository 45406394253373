import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import CoolLightBox from 'vue-cool-lightbox';

// import socketio from 'socket.io';
import VueSocketIO from 'vue-socket.io';
import io from "socket.io-client";
import * as LottiePlayer from "@lottiefiles/lottie-player";

let authCode = '';
try {
  let userString = localStorage.getItem('user');
  if (userString) {
    let userObject = JSON.parse(userString);
    if (userObject && userObject.auth_code) {
      authCode = userObject.auth_code;
    }
  }
} catch(error) {
}

export const SocketInstance  = io(process.env.VUE_APP_URL_CHAT_SOCKET, {
  extraHeaders: {
    "X-Authorization": authCode,
  },
});
Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketInstance
}));

Vue.config.productionTip = false;

Vue.use(CoolLightBox);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LottiePlayer);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
