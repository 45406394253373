<template>
  <div class="chat_detail">
    <div class="chat_detail__heading">
      <div class="chat_detail__heading__body">{{ dataRoom.chat_room_id.room_name }}</div>

    </div>


    <div class="chat_detail__media">
      <div class="chat_detail__media__header">
        <svg width="20" height="20" viewBox="0 0 512 512">
          <path
            d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z" />
        </svg>
        INFO
      </div>
      <!-- {{ JSON.stringify(dataRoom.chat_room_id) }} -->
      <div class="chat_detail__media__body">

        <div class="chat_detail__member_body" v-if="dataRoom && dataRoom.chat_room_id.room_image.media_thumbnail">
          <div class="chat_detail__member_body__item__avatar">
            <img :src="
              dataRoom && dataRoom.chat_room_id.room_image.media_thumbnail
                ? dataRoom.chat_room_id.room_image.media_thumbnail
                : '/chat/images/user_default.png'
            " @error="replaceByDefault" />
          </div>

          <div class="title">Room name: {{ dataRoom.chat_room_id.room_name }}</div>
          <div class="title">Description: {{ dataRoom.chat_room_id.room_description }}</div>
          <div class="title">Limit number: {{ dataRoom.chat_room_id.room_limit_number }}</div>
          <div class="title">Chat history: {{ dataRoom.chat_room_id.chat_history_count }}</div>
          <div class="title">Member: {{ dataRoom.chat_room_id.partner_count }}</div>
          <div class="title">Created At: {{ (momentNew(dataRoom.chat_room_id.createdAt, 'DD-MM-YYYY')) }}</div>

        </div>

      </div>
    </div>

    <div class="chat_detail__member">
      <div class="chat_detail__media__header">
        <svg width="20" height="20" viewBox="0 0 640 512">
          <path
            d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3c-95.73 0-173.3 77.6-173.3 173.3C0 496.5 15.52 512 34.66 512H413.3C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304zM479.1 320h-73.85C451.2 357.7 480 414.1 480 477.3C480 490.1 476.2 501.9 470 512h138C625.7 512 640 497.6 640 479.1C640 391.6 568.4 320 479.1 320zM432 256C493.9 256 544 205.9 544 144S493.9 32 432 32c-25.11 0-48.04 8.555-66.72 22.51C376.8 76.63 384 101.4 384 128c0 35.52-11.93 68.14-31.59 94.71C372.7 243.2 400.8 256 432 256z" />
        </svg>
        MEMBERS ({{ dataRoom.chat_room_id.partner_count }})
      </div>
      <div class="chat_detail__member__add" v-on:click="handleAddMember()" v-if="userData.user_role === 'admin'">
        <svg with="20" height="20" viewBox="0 0 512 512">
          <path
            d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 368C269.3 368 280 357.3 280 344V280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H280V168C280 154.7 269.3 144 256 144C242.7 144 232 154.7 232 168V232H168C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H232V344C232 357.3 242.7 368 256 368z" />
        </svg>
      </div>

      <!-- {{ JSON.stringify(roomMember) }} -->

      <div class="chat_detail__member_body">
        <div class="chat_detail__member_body__item" v-for="(chatItem, index) in roomMember" :key="'chat_item_' + index">
          <div class="chat_detail__member_body__item__avatar">
            <img :src="
              chatItem.user_id && chatItem.user_id.user_avatar
                ? chatItem.user_id.user_avatar
                : '/chat/images/user_default.png'
            " @error="replaceByDefault" />

            <div class="chat_detail__member_body__item__status" v-if="Number(chatItem.user_id)"></div>
          </div>

          <div class="chat_detail__member_body__item__title">
            <div class="title">{{ chatItem.user_id.display_name }}</div>
          </div>

        </div>
      </div>
      <button type="button" class="btn btn-outline-primary w-100" v-on:click="handleLoadMore">
        LOAD MORE</button>
    </div>

    <div class="chat_detail__media">
      <div class="chat_detail__media__header">
        <svg width="20" height="20" viewBox="0 0 512 512">
          <path
            d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z" />
        </svg>
        MEDIA
      </div>
      <div class="chat_detail__media__body">
        <div class="chat_detail_empty" v-if="dataMediaPreview.length === 0">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M326.1 160l127.4-127.4C451.7 32.39 449.9 32 448 32h-86.06l-128 128H326.1zM166.1 160l128-128H201.9l-128 128H166.1zM497.7 56.19L393.9 160H512V96C512 80.87 506.5 67.15 497.7 56.19zM134.1 32H64C28.65 32 0 60.65 0 96v64h6.062L134.1 32zM0 416c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64V192H0V416z" />
            </svg>
          </div>
          <p>Empty media in room!</p>
        </div>

        <!-- {{ JSON.stringify(dataMediaPreview) }} -->

        <div class="chat_detail__media__body__item" v-for="(itemImage, index) in dataMediaPreview"
          :key="'data_media_preview_' + index">
          <div class="chat_detail__media__body__item__border"
            v-on:click="handleOpenImageMedia(itemImage.media_url, 'image')" v-if="itemImage.media_type === 'image'">
            <img :src="itemImage.media_url" />
          </div>
          <div class="chat_detail__media__body__item__border" v-if="itemImage.media_type === 'video'"
            v-on:click="handleOpenVideo(itemImage.media_url)">
            <div class="chat__message__default_play">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="exampleModalCenter" tabindex="1" role="dialog" aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true" style="display: block; opacity: 1" v-if="openPopup">
      <div class="modal-dialog modal-dialog-centered modal_add_user" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 style="font-weight: 700" class="modal-title" id="exampleModalLongTitle">
              Add member to Chat
            </h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="
              () => {
                openPopup = false;
              }
            ">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="input_search__block">
              <svg viewBox="0 0 512 512">
                <path
                  d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
              </svg>

              <input type="email" class="form-control input_search__heading" id="exampleFormControlInput1"
                v-model="searchEmail" placeholder="Search by E-mail..." />

              <div class="spinner-border text-light" style="
                  text-align: center;
                  margin-top: 40px;
                  margin-bottom: 40px;
                  margin-left: auto;
                  margin-right: auto;
                " role="status" v-if="loadingSearch"></div>
              <div class="search_empty" v-if="userSearchData.length === 0 && !loadingSearch">
                Not have user data!
              </div>
              <div class="search_user__data" v-if="userSearchData.length && !loadingSearch">
                <div class="search_user__data__item" v-for="(item, index) in userSearchData"
                  :key="'user_search_' + index">
                  <div class="search_user__data__item__checkbox">
                    <input class="form-check-input" type="checkbox" :value="item.user_id"
                      :id="'checkbox_' + item.user_id" v-on:change="(value) => handleChangeRadio(value)" />
                  </div>
                  <div class="search_user__data__item__avatar">
                    <img :src="
                      item && item.user_avatar
                        ? item.user_avatar
                        : '/chat/images/user_default.png'
                    " />
                  </div>
                  <div class="search_user__data__item__title">
                    <div class="search_user__data__item__name">
                      {{ item.display_name }}
                    </div>
                    <div class="search_user__data__item__email">
                      {{ item.user_email }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="search_data__radio">
                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="read"
                  v-model="permission" />
                <label class="form-check-label" for="exampleRadios1">
                  Read only
                </label>

                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                  v-model="permission" value="write" />
                <label class="form-check-label" for="exampleRadios2">
                  Normal chat
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="
              () => {
                openPopup = false;
              }
            ">
              Close
            </button>
            <button type="button" class="btn btn-primary" v-on:click="handleSetPermission()">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>

    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>

    <div class="chat_preview_background" v-if="videoModal">
      <button type="button" title="Close" class="chat_preview_background__close" v-on:click="closeVideo()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z">
          </path>
        </svg>
      </button>

      <div class="chat_preview_background__click" v-on:click="closeVideo()"></div>
      <div class="chat_preview_background__block">
        <video crossorigin playsinline id="player">
          <!-- Video files -->
          <source :src="videoSource" type="video/mp4" />
        </video>
      </div>
    </div>

    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="display: block; opacity: 1" v-if="audioModal">
      <div class="modal-dialog modal-dialog-centered" role="document" style="height: auto">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLongTitle">
              {{ mediaName }}
            </h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" v-on:click="
                () => {
                  audioModal = false;
                  audioSource = '';
                }
              ">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <audio controls style="width: 100%">
              <source :src="audioSource" />
            </audio>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="
  () => {
    audioModal = false;
    audioSource = '';
  }
            ">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import CoolLightBox from "vue-cool-lightbox";
import Plyr from "plyr";
// import { orderService } from "../services/order.service";

export default {
  props: [
    "dataRoom",
    "reloadMediaProp",
    "reloadAudioProp",
    "reloadRoomMember",
    "roomId",
    "userID",
  ],
  components: {
    CoolLightBox,
  },
  data() {
    let currentTime = new Date().getTime();
    return {
      currentTime: currentTime,
      roomMember: [],
      memberRoom: [],
      searchEmail: "",
      searchTimeout: null,
      userData: null,
      loadingSearch: false,
      userSearchData: [],
      chooseUserIds: [],
      permission: "write",
      openPopup: false,
      dataMediaPreview: [],
      dataUser: [],
      mediaToView: [],
      audioPreview: [],
      audioToView: [],
      reloadMember: 0,
      reloadMedia: 0,
      reloadAudio: 0,
      items: [],
      index: null,
      currentSelectMedia: "",
      audioModal: false,
      audioSource: "",
      mediaName: "",
      timeout: null,
      orderObject: null,
      countDownH: "00",
      countDownM: "00",
      countDownS: "00",
      timeLeftSession: 0,
      disableCountdownTime: false,
      videoSource: "",
      videoModal: false,
      userPage: 1,
      userLimit: 10,
      isLoadMore: false,
    };
  },
  created() {
    if (!this.roomId) {
      return false;
    }
    let userData = localStorage.getItem("user");
    if (userData) {
      try {
        let userDataObject = JSON.parse(userData);
        let userDataPrepare = userDataObject;
        this.userData = userDataPrepare;
        let userToken = userDataPrepare.auth_code;
        let self = this;
        setTimeout(() => {
          self.handleGetRoomMember(userToken, this.userPage, this.userLimit);
        }, 600);

        this.handleGetMediaByRoom(userToken, 1, 6, "image,video");
        this.handleGetMediaByRoom(userToken, 1, 6, "audio");
        this.handleGetOrder(
          this.orderPage,
          this.orderLimit,
          this.orderStatus,
          this.userToken
        );
        this.handleProcessTime(this.dataRoom);
        setTimeout(() => {
          self.handleGetViewRoom(this.roomId, userToken);
        }, 600);
      } catch (error) {
        //Process error;
      }
    } else {
    }
  },
  methods: {
    /**
     * @author Tony Vu
     * @function replaceByDefault
     */
    replaceByDefault(e) {
      e.target.src = "/chat/images/user_default.png";
    },
    handleOpenVideo(url) {
      this.videoModal = true;
      this.videoSource = url;
      this.playVideo();
    },
    /**
     * @author Tony Vu
     * @function playVideo
     */
    playVideo() {
      var self = this;
      setTimeout(function () {
        const player = new Plyr("#player", {
          title: "Open video",
          autoplay: true,
          invertTime: false,
          tooltips: { controls: true, seek: true },
          fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true,
          },
          loading: true,
          controls: [
            "play",
            "progress",
            "current-time",
            "duration",
            "mute",
            "volume",
            "settings",
            "fullscreen",
            "play-large",
            "progress",
            "pip",
            "airplay",
          ],
          i18n: {
            qualityBadge: {
              2160: "4K",
              1440: "2K",
              1080: "HD",
              720: "HD",
              576: "SD",
              480: "SD",
            },
          },
        });
      }, 100);
    },
    closeVideo() {
      this.videoModal = false;
      this.videoSource = "";
    },
    handleProcessTime(dataRoom) {
      this.timeLeftSession++;
      let currentTime = Math.floor(Date.now());
      let endTime = new Date(dataRoom.end_time).getTime();
      let totalSecond = Math.floor((endTime - currentTime) / 1000);
      if (endTime - currentTime >= 0) {
        let hourTime = Math.floor(totalSecond / 3600);
        let minuteTime = Math.floor((totalSecond - hourTime * 3600) / 60);
        let secondTime = Math.floor(
          totalSecond - hourTime * 3600 - minuteTime * 60
        );
        this.disableCountdownTime = false;
        this.countDownPost(
          hourTime,
          minuteTime,
          secondTime,
          this.timeLeftSession
        );
      } else {
        this.disableCountdownTime = true;
      }
    },
    handleOpenAudio(url, fileName) {
      this.mediaName = fileName;
      this.audioModal = true;
      this.audioSource = url;
    },
    handleOpenImageMedia(mediaUrl, type) {
      if (type === "video") {
        this.items = [mediaUrl];
        this.index = 0;
      } else {
        let userData = this.userData;
        let userToken = userData.auth_code;
        this.currentSelectMedia = mediaUrl;
        this.handleGetMediaByRoom(userToken, 1, 30, "image,video", false);
      }
    },
    handleChangeRadio(value) {
      let userChoose = value.target.value;
      if (this.chooseUserIds.indexOf(userChoose) === -1) {
        this.chooseUserIds.push(userChoose);
      } else {
        let indexChoose = this.chooseUserIds.indexOf(userChoose);
        this.chooseUserIds.splice(indexChoose, 1);
      }
    },
    handleAddMember() {
      this.openPopup = true;
      this.handleSearchText("");
      this.chooseUserIds = [];
      this.permission = "write";
    },
    momentNew(dateTime, format) {
      return moment(dateTime).format(format);
    },
    handleLoadMore() {
      let newPage = this.userPage + 1;
      this.userPage = newPage;
      // let userData = this.userData;
      // let userToken = userData.auth_code;
      // this.handleGetRoomMember(userToken, newPage, this.userLimit)
    },
    handleGetRoomMember(userToken, userPage, userLimit) {
      if (this.dataRoom.user_permission === 'write') {

        this.isLoading = true;
        let self = this;
        const config = {
          headers: {
            "X-Authorization": userToken,
          },
        };
        axios
          .get(
            process.env.VUE_APP_CHAT_URL + "/chat-room/member/" + this.roomId + "?page=" + userPage + "&limit=" + userLimit,
            config
          )
          .then((response) => {
            if (response && response.data) {
              this.roomMember = this.roomMember.concat(response.data);
              // let roomMemberData = response.data;
              // if (roomMemberData && roomMemberData.length) {
              //   this.roomMember = roomMemberData;
              // }

            }
          })
          .catch((error) => {
            alert(error.response.data.message);
          });
      }
    },
    countDownPost(h, i, s, session) {
      if (this.timeLeftSession !== session) {
        return false;
      }

      h = Number(h);
      i = Number(i);
      s = Number(s);

      if (s === -1) {
        i -= 1;
        s = 59;
      }
      if (i === -1) {
        h -= 1;
        i = 59;
      }
      if (h == -1) {
        //Reload page
        window.location.reload();
      }

      var s_show = s;
      var i_show = i;
      var h_show = h;
      if (s < 10) {
        s_show = "0" + s;
      }
      if (i < 10) {
        i_show = "0" + i;
      }
      if (h < 10) {
        h_show = "0" + h;
      }

      this.countDownH = h_show;
      this.countDownM = i_show;
      this.countDownS = s_show;
      var self = this;
      this.timeout = setTimeout(function () {
        s--;
        self.countDownPost(h, i, s, session);
      }, 1000);
    },

    handleGetViewRoom(roomId, userObject) {

      this.isLoading = true;
      let self = this;
      const config = {
        headers: {
          "X-Authorization": userObject,
        },
      };

      axios
        .post(process.env.VUE_APP_CHAT_URL + "/chat-room/view/" + roomId, {}, config)
        .then((response) => {
          if (response && response.data) {
            let roomData = response.data;
            let currentUser = self.userData.user_id;
            let advisorData = response.data.partner_id;
            this.dataAdvisor = advisorData;

            // if (response.data.chat_room_id) {
            //   this.chatId = response.data.chat_room_id;
            // }
            if (response.data.chat_room_id) {
              this.chatId = roomId;
            }

            // this.dataRoom = roomData;
          } else {
            alert("Have an Error, please try again!");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error)
          alert(error?.response.data.message);
          this.isLoading = false;
        });
    },

    handleGetMediaByRoom(userToken, page, limit, type, isMediaPreview = true) {
      if (this.dataRoom.user_permission === 'write') {
        this.isLoading = true;
        let self = this;
        const config = {
          headers: {
            "X-Authorization": userToken,
          },
        };
        let urlMedia =
          process.env.VUE_APP_CHAT_URL +
          "/chat-media/room/" +
          this.roomId +
          `?page=${page}&limit=${limit}`;
        axios
          .get(urlMedia, config)
          .then((response) => {
            if (response && response.data) {
              let dataMediaReturn = response.data;
              if (dataMediaReturn && dataMediaReturn.length) {
                if (type === "image,video") {
                  if (isMediaPreview) {
                    this.dataMediaPreview = dataMediaReturn;
                  } else {
                    this.mediaToView = dataMediaReturn;
                  }
                }
                if (type === "audio") {
                  if (isMediaPreview) {
                    this.audioPreview = dataMediaReturn;
                  } else {
                    this.audioToView = dataMediaReturn;
                  }
                }
              } else {
                if (type === "image,video") {
                  if (isMediaPreview) {
                    this.dataMediaPreview = [];
                  } else {
                    this.mediaToView = [];
                  }
                }
                if (type === "audio") {
                  if (isMediaPreview) {
                    this.audioPreview = [];
                  } else {
                    this.audioToView = [];
                  }
                }
              }
            }
          })
          .catch((error) => {
            alert(error.response.data.message);
            if (type === "image,video") {
              if (isMediaPreview) {
                this.dataMediaPreview = [];
              } else {
                this.mediaToView = [];
              }
            }
            if (type === "audio") {
              if (isMediaPreview) {
                this.audioPreview = [];
              } else {
                this.audioToView = [];
              }
            }
          });
      }
    },


    handleSetPermission() {
      if (this.chooseUserIds.length === 0) {
        alert("Please choose user!");
      }

      const params = new URLSearchParams();
      let userIdToString = this.chooseUserIds.join(",");

      params.append("user_id", userIdToString);
      params.append("chat_room_id", this.roomId);
      params.append("role", this.permission);

      let userToken = this.userData.auth_code;

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Authorization": userToken,
        },
      };
      this.isLoading = true;
      axios
        .post(
          process.env.VUE_APP_CHAT_URL + "/chat-room/user-role",
          params,
          config
        )
        .then((response) => {
          if (response && response.data) {
            this.reloadMember++;
            this.openPopup = false;
          } else {
          }
          this.isLoading = false;
        })
        .catch((error) => {
          alert(error.response.data.message);
          this.isLoading = false;
        });
    },
    handleRemoveUserRole(userId) {
      const params = new URLSearchParams();
      params.append("user_id", userId);
      params.append("chat_room_id", this.roomId);

      let userToken = this.userData.auth_code;

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Authorization": userToken,
      };
      this.isLoading = true;
      axios
        .delete(process.env.VUE_APP_CHAT_URL + "/chat-room/user-role", {
          data: params,
          headers: headers,
        })
        .then((response) => {
          if (response && response.data) {
            this.reloadMember++;
          } else {
          }
          this.isLoading = false;
        })
        .catch((error) => {
          alert(error.response.data.message);
          this.isLoading = false;
        });
    },
    handleSearchText(text) {
      this.loadingSearch = true;
      let self = this;

      let userToken = this.userData.auth_code;
      const config = {
        headers: {
          "X-Authorization": userToken,
        },
      };
      let urlSearch =
        process.env.VUE_APP_CHAT_URL +
        "/chat-user/list?page=1&limit=10&search=" +
        text;
      axios
        .get(urlSearch, config)
        .then((response) => {
          if (response && response.data) {
            this.userSearchData = response.data;
          }
          self.loadingSearch = false;
        })
        .catch((error) => {
          self.loadingSearch = false;
          alert(error.response.data.message);
        });
    },
  },
  watch: {
    dataRoom(newData) {
      this.roomMember = [];
      this.userPage = 1;
      this.handleProcessTime(newData);
      // this.handleGetOrderObject(
      //   this.orderPage,
      //   this.orderLimit,
      //   newData,
      //   this.userToken
      // );
    },
    roomId(newData) {
      let userData = this.userData;
      let userToken = userData.auth_code;
      this.handleGetViewRoom(newData, userToken);
    },

    userPage(newData) {
      let userData = this.userData;
      let userToken = userData.auth_code;
      this.handleGetRoomMember(userToken, newData, this.userLimit)
    },



    mediaToView(newData) {
      let dataMedia = [];
      if (newData && newData.length) {
        for (let itemData of newData) {
          dataMedia.push(itemData.media_url);
        }
      }
      this.items = dataMedia;
      let index = dataMedia.indexOf(this.currentSelectMedia);
      this.index = index;
    },
    reloadMediaProp(newData) {
      if (newData) {
        this.reloadMedia = this.reloadMedia + newData;
      }
    },
    reloadAudioProp(newData) {
      if (newData) {
        this.reloadAudio = this.reloadAudio + newData;
      }
    },
    reloadRoomMember(newData) {
      if (newData) {
        this.reloadMember = this.reloadMember + newData;
      }
    },
    reloadMember(newData) {
      if (newData) {
        let userData = this.userData;
        let userToken = userData.auth_code;
        this.handleGetRoomMember(userToken, this.userPage, this.userLimit);
      }
    },
    reloadMedia(newData) {
      if (newData) {
        let userData = this.userData;
        let userToken = userData.auth_code;
        this.handleGetMediaByRoom(userToken, 1, 6, "image,video");
      }
    },
    reloadAudio(newData) {
      if (newData) {
        let userData = this.userData;
        let userToken = userData.auth_code;
        this.handleGetMediaByRoom(userToken, 1, 6, "audio");
      }
    },
    searchEmail(newData) {
      clearTimeout(this.searchTimeout);
      let self = this;
      this.searchTimeout = setTimeout(() => {
        //Process Text
        self.handleSearchText(newData);
      }, 800);
    },
  },
};
</script>

<style lang="scss">
.clock_block {
  width: 100%;
  float: left;
  background: #f1edf3;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  padding: 8px 20px;
  border: 1px #5e0d8d solid;
}

.clock_h,
.clock_s,
.clock_i {
  width: 30%;
  float: left;
  color: #5e0d8d;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}

.clock_text {
  width: 5%;
  float: left;
  color: #5e0d8d;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}

.chat_detail_empty {
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  background: #f5f5f5;
  color: #888;
  font-size: 15px;
  font-weight: 400;
}

.chat_detail_empty div {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.chat_detail_empty div svg {
  width: 26px;
  height: 26px;
  fill: #aaa;
}

.chat_detail_empty p {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 8px;
}

.form-check-label {
  margin-right: 16px;
}

.search_data__radio {
  width: 100%;
  text-align: left;
}

.search_user__data {
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-check-input {
  margin-left: 10px;
  margin-right: 8px;
}

.search_user__data__item__avatar {
  width: 40px;
  height: 40px;
  // float: left;
}

.search_user__data__item__avatar img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.search_user__data__item__checkbox {
  width: 40px;
  height: 40px;
  float: left;
  text-align: left;
}

.search_user__data__item__checkbox input {
  margin: 10px;
}

.search_user__data__item {
  width: 100%;
  float: left;
  border-bottom: 1px #efefef solid;
  padding: 10px 0;
}

.search_user__data__item__title {
  width: calc(100% - 80px);
  padding: 0 0 0 16px;
  float: left;
}

.search_user__data__item__name {
  width: 100%;
  text-align: left;
  font-size: 16px;
  margin-top: -4px;
  font-weight: 700;
  float: left;
  color: #444;
}

.search_user__data__item__email {
  width: 100%;
  float: left;
  text-align: left;
  font-size: 14px;
  color: #aaa;
}

.chat_detail__member_body {
  width: 100%;
  float: left;
}

.chat_detail__member_body__item {
  width: 100%;
  float: left;
  padding: 10px 0;
  border-bottom: 1px #efefef solid;
}

.chat_detail__member_body__item__avatar {
  width: 40px;
  height: 40px;
  float: left;
  position: relative;
  margin-top: 4px;
}

.chat_detail__member_body__item__status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #00afb9;
  border: 3px #fff solid;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.chat_detail__member_body__item__avatar img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}

.chat_detail__member_body__item__title {
  width: calc(100% - 80px);
  padding: 0 0 0 16px;
  float: left;
}

.chat_detail__member_body__item__svg {
  width: 40px;
  height: 40px;
  text-align: right;
  float: left;
  cursor: pointer;
}

.chat_detail__member_body__item__svg svg {
  fill: #aaa;
  margin-top: 4px;
}

.chat_detail__member_body__item__title .title {
  font-size: 16px;
  font-weight: 700;
  color: #444;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin-top: 8px
}

.chat_detail__member_body__item__title .description {
  font-size: 14px;
  font-weight: 400;
  color: #aaa;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.chat_detail {
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding-bottom: 100px;
  overflow-y: auto;
}

.chat_detail__heading__body {
  font-size: 24px;
  font-weight: 700;
  color: #444;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat_detail__heading__note {
  background-color: #cff2ee;
  border-radius: 8px;
  border: 1px #238075 solid;
  padding: 10px 20px;
  text-align: center;
  margin-top: 8px;
  font-weight: 700;
  font-size: 12px;
  color: #238075;
}

.chat_detail__heading__note.disable {
  background-color: #fdeded;
  border: 0;
  color: #f08080;
  border: 1px #f08080 solid;
}

.chat_detail__media__header {
  width: calc(100% - 40px);
  float: left;
  margin-top: 16px;
  font-weight: 700;
  font-size: 18px;
  color: #888;
}

.chat_detail__member__add {
  width: 40px;
  float: left;
  cursor: pointer;
  text-align: right;
  margin-top: 16px;
}

.chat_detail__member__add svg {
  fill: #888;
}

.chat_detail__media__header svg {
  fill: #888;
  margin-right: 8px;
  margin-top: -4px;
}

.search_empty {
  width: 100%;
  float: left;
  padding: 40px 20px;
  color: #aaa;
  font-size: 14px;
  font-weight: 400;
}

.modal-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 60px);
  font-size: 20px;
}

.input_search__block {
  width: 100%;
  margin-top: 16px;
  float: left;
  position: relative;
  text-align: center;
}

.input_search__block svg {
  position: absolute;
  top: 15px;
  left: 15px;
  fill: #ccc;
  width: 18px;
  height: 18px;
}

.input_search__heading {
  background-color: #f5f5f5 !important;
  border: 1px #f5f5f5 solid !important;
  padding: 12px 0.75rem 12px 40px !important;
  float: left;
}

.chat_detail__media__body__item__border {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.chat_detail__media__body__item__border img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat_detail__media__body__item__border video {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.chat_detail__media {
  width: 100%;
  float: left;
}

.chat_detail__media__body {
  width: 100%;
  float: left;
  margin-top: 16px;

  &__button {
    font-size: 16px !important;
    font-weight: 700 !important;
  }
}

.chat_detail__media__body__item {
  width: 100%;
}

.chat_detail__media__body__item__audio {
  width: 100%;
  border-bottom: 1px #efefef solid;
  cursor: pointer;
  user-select: none;
  padding: 10px 0;
}

.chat_detail__media__body__item__audio svg {
  margin-right: 8px;
  fill: #e76f51;
  margin-top: -4px;
}

.modal-dialog,
.modal-content {
  /* 80% of window height */
  height: 80%;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px #efefef solid !important;
  border-radius: 16px !important;
}

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}
</style>
