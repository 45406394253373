<template>
  <div class="form">
    <input class="form__input" type="text" placeholder="Enter your message" v-model="msg" @keydown="handleKeyDown"
      @focus="handleFocusForm" @blur="handleBlurForm" @keyup.enter="submitMessage" />

    <input type="file" ref="file" id="chat_send_file" multiple="multiple" style="width: 0px; height: 0px; opacity: 0"
      v-on:change="handleSubmitFile" accept="image/*,video/*" />

    <!-- <input type="file" ref="file_audio" id="chat_send_file_audio" multiple="multiple"
      style="width: 0px; height: 0px; opacity: 0" v-on:change="handleSubmitFile" accept="audio/*" /> -->

    <div class="form__submit" v-on:click="() => handleClickFile()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z" />
      </svg>
    </div>
    <!-- <div class="form__submit" v-on:click="() => handleClickFileAudio()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path
          d="M192 352c53.03 0 96-42.97 96-96v-160c0-53.03-42.97-96-96-96s-96 42.97-96 96v160C96 309 138.1 352 192 352zM344 192C330.7 192 320 202.7 320 215.1V256c0 73.33-61.97 132.4-136.3 127.7c-66.08-4.169-119.7-66.59-119.7-132.8L64 215.1C64 202.7 53.25 192 40 192S16 202.7 16 215.1v32.15c0 89.66 63.97 169.6 152 181.7V464H128c-18.19 0-32.84 15.18-31.96 33.57C96.43 505.8 103.8 512 112 512h160c8.222 0 15.57-6.216 15.96-14.43C288.8 479.2 274.2 464 256 464h-40v-33.77C301.7 418.5 368 344.9 368 256V215.1C368 202.7 357.3 192 344 192z" />
      </svg>
    </div> -->


    <div class="form__submit" v-on:click="() => { isOpenSticker = true }">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512">
        <path
          d="M349.5 308.4C368.2 303.1 385.4 320.4 374.1 336.5C350.4 374.6 306.3 399.1 255.9 399.1C205.6 399.1 161.5 374.6 136.9 336.5C126.5 320.4 143.7 303.1 162.3 308.4C191.3 315.1 222.8 318.8 255.9 318.8C289 318.8 320.6 315.1 349.5 308.4zM238.9 177.1L221.4 243C219.1 251.6 210.4 256.6 201.8 254.3L136.7 236.9C118.9 232.1 108.4 213.8 113.1 196.1C117.9 178.3 136.2 167.7 153.1 172.5L170.1 176.8L174.4 160.7C179.2 142.9 197.5 132.4 215.3 137.1C233.1 141.9 243.6 160.2 238.9 177.1H238.9zM341.9 176.8L358 172.5C375.8 167.7 394.1 178.3 398.9 196.1C403.6 213.8 393.1 232.1 375.3 236.9L310.2 254.3C301.6 256.6 292.9 251.6 290.6 243L273.1 177.1C268.4 160.2 278.9 141.9 296.7 137.1C314.5 132.4 332.8 142.9 337.6 160.7L341.9 176.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
      </svg>
    </div>

    <div @click="submitMessage" class="form__submit">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M501.6 4.186c-7.594-5.156-17.41-5.594-25.44-1.063L12.12 267.1C4.184 271.7-.5037 280.3 .0431 289.4c.5469 9.125 6.234 17.16 14.66 20.69l153.3 64.38v113.5c0 8.781 4.797 16.84 12.5 21.06C184.1 511 188 512 191.1 512c4.516 0 9.038-1.281 12.99-3.812l111.2-71.46l98.56 41.4c2.984 1.25 6.141 1.875 9.297 1.875c4.078 0 8.141-1.031 11.78-3.094c6.453-3.625 10.88-10.06 11.95-17.38l64-432C513.1 18.44 509.1 9.373 501.6 4.186zM369.3 119.2l-187.1 208.9L78.23 284.7L369.3 119.2zM215.1 444v-49.36l46.45 19.51L215.1 444zM404.8 421.9l-176.6-74.19l224.6-249.5L404.8 421.9z" />
      </svg>
    </div>


    <div style="" class="background_border" v-if="isOpenSticker">
      <div class="background_sticker" v-on:click="() => { isOpenSticker = false }" v-if="isOpenSticker"></div>
      <Picker :data="emojiIndex" set="apple" :size="32" :showPreview="false" @select="showEmoji" />
    </div>
  </div>
</template>

<script>
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
// Vue 2:
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
let emojiIndex = new EmojiIndex(data)

export default {
  created() {
    let dataUser = localStorage.getItem("user");
    let userData = null;
    if (dataUser) {
      try {
        let dataUserObject = JSON.parse(dataUser);
        userData = dataUserObject;
      } catch (error) {
        userData = this.$route.params.userData;
      }
    } else {
      userData = this.$route.params.userData;
    }
    if (userData) {
      this.userData = userData;
    } else {
      alert("Please login to continue!");
      this.$router.push({ path: "/" });
    }
  },
  components: {
    Picker
  },
  props: {
    dataRoom: Object,
    statusTyping: Boolean,
  },
  data() {
    return {
      msg: "",
      userData: null,
      socket: null,
      timeOutTyping: null,
      isFirstPing: false,
      isInForm: false,
      emojiIndex: emojiIndex,
      isOpenSticker: false,
    };
  },
  methods: {
    showEmoji(emoji) {
      this.msg = this.msg + emoji.native;
    },
    handleKeyDown(e) {
      if (this.isFirstPing) {
        this.$emit("sendEmitTyping", true);
        this.isFirstPing = false;
      }
      let self = this;
      if (!this.timeOutTyping) {
        this.timeOutTyping = setTimeout(function () {
          if (self.isInForm) {
            self.$emit("sendEmitTyping", true);
          }
          self.timeOutTyping = null;
          self.isFirstPing = true;
        }, 3000);
      }
    },
    handleFocusForm() {
      this.isInForm = true;
      this.isFirstPing = true;
    },
    handleBlurForm() {
      this.isFirstPing = false;
      this.isInForm = false;
    },
    submitMessage() {
      let message = this.msg.trim();
      if (message) {
        this.$emit("submitMessage", message);
        this.msg = "";
      } else {
        this.msg = "";
      }
      return;
    },
    handleSubmitFile(dataFile) {
      this.$emit("handleSubmitFile", dataFile);
      this.$refs.file.value = null;
      this.$refs.file_audio.value = null;
      return;
    },
    handleClickFile() {
      document.getElementById("chat_send_file").click();
    },
    handleClickFileAudio() {
      document.getElementById("chat_send_file_audio").click();
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.form {

  position: fixed;
  width: calc(100% - 361px - 301px);
  bottom: 0;
  left: 360px;
  justify-content: space-between;
  padding: 20px 14px 20px 20px;
  line-height: 30px;
  background: #ffffff;
  height: 70px;
  border-top: 1px #efefef;

  @include breakpoint(phablet) {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }

}

.background_sticker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;
}

.form__input {
  border: none;
  padding: 0;
  font-size: 16px;
  width: calc(100% - 160px);
  float: left;
}

.form__input:focus {
  outline: none;
}

.form__submit {
  align-items: center;
  cursor: pointer;
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-top: -4px;
  border-radius: 8px;

  &:hover {
    background-color: #efefef;
  }

  svg {
    width: 22px;
    height: 22px;
    fill: #999;
    cursor: pointer;
    user-select: none;
    margin: 0 auto;
    margin-top: 6px;
  }
}

svg {
  transition: 0.3s;
}

svg:hover {
  fill: #999999;
}

.background_border {
  position: fixed;
  bottom: 80px;
  left: 370px;
  right: 310px;
  z-index: 0;

  @include breakpoint(phablet) {
    left: 0px;
    right: 2px;
    bottom: 70px;
  }
}

.emoji-mart {
  z-index: 200;
  position: relative;
}
</style>
