import { axiosGetInstanceBackend, axiosPostInstanceBackend } from "../helpers/axios-config";

class loginClass {
  login = async (dataLogin) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Authorization": "",
      },
    };

    return await axiosPostInstanceBackend(process.env.VUE_APP_MAIN_BACKEND_URL + "/login", dataLogin, config);
  };

  register = async (dataLogin) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Authorization": "",
      },
    };

    return await axiosPostInstanceBackend(process.env.VUE_APP_MAIN_BACKEND_URL + "/register", dataLogin, config);
  };
  loginWithGoogle = async (dataLogin) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Authorization": "",
      },
    };
    return await axiosPostInstanceBackend(process.env.VUE_APP_MAIN_BACKEND_URL + "/user/login/google", dataLogin, config);
  };

  logout = async (userToken) => {
    if (userToken) {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Authorization": userToken,
        },
      };
      return await axiosGetInstanceBackend(process.env.VUE_APP_MAIN_BACKEND_URL + "/user/logout", config);
    }
  }

  loginWithFacebook = async (dataLogin) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Authorization": "",
      },
    };

    return await axiosPostInstanceBackend(process.env.VUE_APP_MAIN_BACKEND_URL + "/user/login/facebook", dataLogin, config);
  };
}

export const loginService = new loginClass();
