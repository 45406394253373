<template>
  <div>
    <login-form></login-form>
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
  components: {
    LoginForm: LoginForm,
  },
};
</script>

<style></style>
