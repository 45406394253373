<template>
  <div class="chat">
    <div class="body_open_left" v-if="isOpenLeftMenu">
      <group-chat-category-list :chatId="dataRoom.chat_room_id" v-if="dataRoom.chat_room_id" />
    </div>

    <div class="body_open_right" v-if="isOpenRightMenu">
      <div v-if="dataRoom && dataRoom.chat_room_id">
        <group-chat-detail :dataRoom="dataRoom" :reloadMediaProp="reloadMediaProp" :reloadAudioProp="reloadAudioProp"
          :reloadRoomMember="reloadRoomMember" :roomId="dataRoom.chat_room_id" :userID="(dataAdvisor && dataAdvisor._id)
            ? dataAdvisor._id
            : 'LOL'
          " />
      </div>
    </div>

    <div class="body_open_background" v-if="isOpenLeftMenu || isOpenRightMenu" v-on:click="
      () => {
        isOpenLeftMenu = false;
        isOpenRightMenu = false;
      }
    "></div>

    <div class="body_chat_left">
      <group-chat-category-list :chatId="chatId" />
    </div>
    <div class="body_chat_center">
      <div class="chat__header">
        <div class="body_chat_menu" v-on:click="
          () => {
            isOpenLeftMenu = true;
          }
        ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">

            <path
              d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z" />
          </svg>
        </div>
        <span class="chat__header__greetings">
          <div class="chat__header__avatar">

            <!-- {{ JSON.stringify(dataRoom.chat_room_id.room_name) }} -->
            <img :src="
              dataRoom && dataRoom.chat_room_id.room_image.media_thumbnail
                ? dataRoom.chat_room_id.room_image.media_thumbnail
                : 'https://i.stack.imgur.com/l60Hf.png'
            " @error="replaceByDefault" />

            <div class="chat__header__avatar__status" v-if="Number(dataAdvisor && dataAdvisor.user_active)"></div>

          </div>
          <div class="chat__header__left">
            {{ dataRoom && dataRoom.chat_room_id.room_name
  ? dataRoom.chat_room_id.room_name
  : null
            }}
          </div>
        </span>
        <div class="body_chat_info" v-on:click="
          () => {
            isOpenRightMenu = true;
          }
        ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

            <path
              d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" />
          </svg>
        </div>
      </div>
      <div class="chat__header__bg"></div>

      <chat-list :msgs="msgData" :userData="userData" :dataAdvisor="dataAdvisor"></chat-list>

      <div style="
          padding: 0 0 10px 0;
          left: 0;
          right: 0;
          position: absolute;
          bottom: 60px;
        " v-if="isLoadingMedia">
        <div class="progress" style="height: 8px; opacity: 0.5; border-radius: 0">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
            aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
      </div>
      <div class="typing_class" v-if="statusTyping">
        {{ messageTyping }}
      </div>

      <div class="chat_expired" v-if="dataRoom && handleCheckTime(dataRoom.end_time)">
        Room is Expired. Can't send message!
      </div>

      <div v-if="dataRoom && dataRoom.user_permission === 'read'" v-on:click="handleJoin">
        <button type="button" class="btn btn-outline-primary w-100">
          JOIN GROUP</button>
      </div>

      <div v-if="dataRoom && dataRoom.user_permission === 'write'">
        <chat-form @handleSubmitFile="handleSubmitFile" @submitMessage="sendMessage" @sendEmitTyping="sendEmitTyping"
          :statusTyping="statusTyping" :dataRoom="dataRoom"></chat-form>
      </div>

      <router-view />
    </div>

    <div class="body_chat_right">
      <div v-if="dataRoom && dataRoom.chat_room_id">
        <group-chat-detail :dataRoom="dataRoom" :reloadMediaProp="reloadMediaProp" :reloadAudioProp="reloadAudioProp"
          :reloadRoomMember="reloadRoomMember" :roomId="chatId" />
      </div>
    </div>

    <div class="background-border" style="text-align: center" v-if="isLoading">
      <div class="spinner-border text-light" role="status"></div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import ChatList from "@/components/ChatList";
import ChatForm from "@/components/ChatForm";
import GroupChatCategoryList from "../components/GroupChatCategoryList.vue";
import GroupChatDetail from "../components/GroupChatDetail.vue";
import Constant from "@/Constant";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import io from "socket.io-client";
import * as fs from "fs";

export default {
  data() {
    let currentTime = new Date().getTime();
    return {
      chatId: "",
      userData: null,
      isLoading: false,
      dataRoom: null,
      dataAdvisor: null,
      msgData: [],
      isLoadingMedia: false,
      socket: null,
      statusTyping: false,
      messageTyping: "",
      currentTime: currentTime,
      reloadMediaProp: -1,
      reloadAudioProp: -1,
      reloadRoomMember: -1,
      firstGetChatRoom: 0,
      stopLoadChat: false,
      chatPage: 1,
      timeOutScroll: null,
      isOpenLeftMenu: false,
      isOpenRightMenu: false,
      currentSendMessage: false,
    };
  },
  sockets: {
    msgToClient(message) {
      var objDiv = document.getElementById("chat__body");
      let objectHeight = objDiv.scrollHeight;
      let scrollTop = objDiv.scrollTop;
      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      let totalScroll = scrollTop + height - 140;

      try {
        if (message) {
          let messageObject = JSON.parse(message);
          if ((this.dataRoom.chat_room_id._id) !== messageObject.chat_room_id) {
            return false;
          }
          if (
            (messageObject.createBy._id !== this.userData._id)
            || ((messageObject.createBy._id === this.userData._id) && !this.currentSendMessage)
          ) {
            this.pushMsgData(messageObject, false);
            //this.handleToBottom();
            if (messageObject.chat_image || messageObject.chat_video) {
              this.reloadMediaProp++;
            }
            if (messageObject.chat_audio) {
              this.reloadAudioProp++;
            }

            if (Number(objectHeight) - Number(totalScroll) <= 0) {
              setTimeout(() => {
                const element = document.getElementById("chat__body");
                element.scrollTop = element.scrollHeight;
              }, 500);
            }
          }
        }
      } catch (error) { }
    },
    typingToClient(data) {
      let self = this;
      try {
        if (data) {
          let dataTyping = JSON.parse(data);
          if (dataTyping.user_id !== this.userData._id) {
            let messageTyping = dataTyping.display_name + " is typing...";
            self.messageTyping = messageTyping;
            self.statusTyping = true;
            setTimeout(function () {
              self.statusTyping = false;
            }, 3000);
          }
        }
      } catch (error) { }
    }
  },
  components: {
    ChatList,
    ChatForm,
    GroupChatCategoryList,
    GroupChatDetail,
  },
  computed: {},
  created() {
    if (!localStorage.getItem("user")) {
      this.$router.push({
        name: "login",
      });
    }
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        if (!isNaN(newValue)) {
          this.chatId = Number(newValue.id);
        }
        this.firstGetChatRoom = 0;
        this.chatPage = 1;
        this.msgData = [];
        this.stopLoadChat = false;
        if (this.reloadMediaProp !== -1) {
          this.handleGetRoomById(newValue.id, this.userData);
        }
        this.processUser();
        this.isOpenLeftMenu = false;
      },
      immediate: true,
    },
    chatId(newData) {
      let groupName = (this.dataRoom.chat_room_id.room_name) ? this.dataRoom.chat_room_id.room_name : null;
      document.title = `Chat with ${groupName} - WhiteG`;
      this.reloadRoomMember++;
      this.reloadMediaProp++;
      this.reloadAudioProp++;
      this.handleGetChatByRoomId(newData, this.userData, this.chatPage);
      this.processSocket(newData);
    }
  },
  beforeMount() { },

  methods: {
    replaceByDefault(e) {
      e.target.src = '/chat/images/user_default.png';
    },
    handleToBottom() {
      var objDiv = document.getElementById("chat__body");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    handleScroll() {
      var self = this;
      if (
        self.$route.path.indexOf("chat/chat") === -1 &&
        self.$route.path.indexOf("chat/list") === -1
      ) {
        window.removeEventListener("scroll", self.handleScroll, true);
      }

      if (!self.isLoading && !self.isLoadingMedia && !self.stopLoadChat) {
        var objDiv = document.getElementById("chat__body");
        let scrollTop = objDiv.scrollTop;
        if (scrollTop === 0 && self.firstGetChatRoom !== 0) {
          let newChatPage = this.chatPage + 1;
          self.handleGetChatByRoomId(this.chatId, this.userData, newChatPage);
          this.chatPage = newChatPage;
        }
      }
    },
    processSocket(roomId) {
      let self = this;
      setTimeout(() => {
        self.$socket.emit("joinRoom", "room_" + roomId);
      }, 2000);
    },
    processUser() {
      let dataUser = localStorage.getItem("user");
      let userData = null;
      if (dataUser) {
        try {
          let dataUserObject = JSON.parse(dataUser);
          userData = dataUserObject;
        } catch (error) {
          userData = this.$route.params.userData;
        }
      } else {
        userData = this.$route.params.userData;
      }
      if (userData) {
        this.userData = userData;
        let chatId = this.$route.params?.id;
        this.handleGetRoomById(chatId, userData);
      } else {
        alert("Please login to continue!");
        this.$router.push({ path: "/login" });
      }
    },
    handleCheckTime(stopTime) {
      let stopTimeNumber = new Date(stopTime).getTime();
      if (stopTimeNumber - this.currentTime <= 0) {
        return true;
      } else {
        return false;
      }
    },
    sendEmitTyping(status) {
      this.$socket.emit("typingToServer", "room_" + this.dataRoom.chat_room_id._id);
    },
    momentNew(dateTime, format) {
      return moment(dateTime).format(format);
    },
    handleProcess(loaded) { },

    handleJoin() {
      this.isLoading = true;
      let self = this;
      let userData = this.userData;
      let userToken = userData.auth_code;
      const config = {
        headers: {
          "X-Authorization": userToken,
        },
      };
      // console.log(this.chatId)
      axios
        .post(process.env.VUE_APP_CHAT_URL + "/chat-room/join/" + this.chatId, {}, config)
        .then((response) => {
          if (response && response.data) {
            setTimeout(() => {
              window.location.reload();
            }, 100);
          } else {
            alert("Have an Error, please try again!");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error)
          alert(error?.response.data.message);
          this.isLoading = false;
        });
    },

    handleGetRoomById(roomId, userObject) {
      this.isLoading = true;
      let self = this;
      const config = {
        headers: {
          "X-Authorization": userObject.auth_code,
        },
      };

      axios
        .get(process.env.VUE_APP_CHAT_URL + "/chat-room/" + roomId, config)
        .then((response) => {
          if (response && response.data) {
            let roomData = response.data;
            let currentUser = self.userData.user_id;
            let advisorData = response.data.partner_id;
            this.dataAdvisor = advisorData;

            // if (response.data.chat_room_id) {
            //   this.chatId = response.data.chat_room_id;
            // }
            if (response.data.chat_room_id) {
              this.chatId = roomId;
            }

            this.dataRoom = roomData;
          } else {
            alert("Have an Error, please try again!");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error)
          alert(error?.response.data.message);
          this.isLoading = false;
        });
    },
    handleGetChatByRoomId(roomId, userObject, chatPage) {
      const config = {
        headers: {
          "X-Authorization": userObject.auth_code,
        },
      };
      let self = this;
      axios
        .get(
          process.env.VUE_APP_CHAT_URL +
          "/chat-history/room/" +
          roomId +
          `?page=${chatPage}&limit=30&order_by=DESC`,
          config
        )
        .then((response) => {
          if (response && response.data) {
            let newData = _.reverse(response.data);
            let lastMessageElm = null;
            let lastId = "";
            if (this.msgData.length !== 0) {
              let lastMessage = this.msgData[0];
              lastId = "chat_message_id_" + lastMessage.chat_history_id;
            }
            this.msgData = [...newData, ...this.msgData];
            setTimeout(() => {
              lastMessageElm = document.getElementById(lastId);
              if (lastMessageElm) {
                lastMessageElm.scrollIntoView();
              }
            }, 200);

            if (this.firstGetChatRoom === 0) {
              setTimeout(() => {
                self.handleToBottom();
              }, 100);
            }

            if (response.data.length === 0) {
              this.stopLoadChat = true;
            }
          } else {
            this.stopLoadChat = true;
          }

          if (this.firstGetChatRoom === 0) {
            let dataChat = document.getElementById("chat__body");
            if (dataChat) {
              dataChat.addEventListener("scroll", self.handleScroll, true);
            }
          }
          this.firstGetChatRoom++;
        })
        .catch((error) => {
          alert(error?.response.data.message);
          if (this.firstGetChatRoom === 0) {
            let dataChat = document.getElementById("chat__body");
            if (dataChat) {
              dataChat.addEventListener("scroll", self.handleScroll, true);
            }
          }
        });
    },
    pushMsgData(data, isScroll = true) {
      this.msgData.push(data);
      let self = this;
      if (isScroll) {
        setTimeout(() => {
          self.handleToBottom();
        }, 200);
      }
    },

    sendMessage(msg) {

      let dataIndex = this.msgData.length;
      this.pushMsgData({
        chat_room_id: this.dataRoom.chat_room_id._id,
        chat_content: msg,
        user_type: this.userData.user_role,
        createBy: this.userData,
      });


      const params = new URLSearchParams();
      params.append("chat_room_id", this.dataRoom.chat_room_id._id);
      params.append("chat_content", msg);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Authorization": this.userData.auth_code,
        },
      };
      this.currentSendMessage = true;
      let self = this;
      axios
        .post(
          process.env.VUE_APP_CHAT_URL + "/chat-history/create",
          params,
          config
        )
        .then((response) => {
          if (response && response.data) {
            this.msgData[dataIndex] = {
              ...this.msgData[dataIndex],
              ...response.data,
              ...{ chat_status: "send" },
              ...{ createBy: this.userData },
            };
            this.msgData = JSON.parse(JSON.stringify(this.msgData));
          } else {
          }
          setTimeout(() => {
            self.currentSendMessage = false;
          }, 2000);
        })
        .catch((error) => {
          setTimeout(() => {
            self.currentSendMessage = false;
          }, 2000);
          alert(error?.response.data.message);

        });

      setTimeout(() => {
        const element = document.getElementById("chat__body");
        element.scrollTop = element.scrollHeight;
      }, 0);
    },

    async handleSubmitFile(dataFile) {
      let fileArray = dataFile.target.files;

      this.isLoadingMedia = true;

      //let filePromises = [];
      for (var i = 0; i < fileArray.length; i++) {
        let file = fileArray[i];
        let mediaPresign = await this.handleCreatePresignUrl(file);


        if (mediaPresign) {

          let dataIndex = this.msgData.length;
          this.pushMsgData({
            chat_room_id: this.dataRoom.chat_room_id._id,
            // chat_content: msg,
            media_data: JSON.stringify([`${mediaPresign[0].callback._id}`]),
            user_type: this.userData.user_role,
            createBy: this.userData,
          });

          const params = new URLSearchParams();
          params.append("chat_room_id", this.dataRoom.chat_room_id._id);
          params.append("media_data", JSON.stringify([`${mediaPresign[0].callback._id}`]));

          const config = {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Authorization": this.userData.auth_code,
            },
          };
          this.currentSendMessage = true;
          let self = this;
          axios
            .post(
              process.env.VUE_APP_CHAT_URL + "/chat-history/create",
              params,
              config
            )
            .then((response) => {
              if (response && response.data) {
                this.msgData[dataIndex] = {
                  ...this.msgData[dataIndex],
                  ...response.data,
                  ...{ chat_status: "send" },
                  ...{ createBy: this.userData },
                };
                this.msgData = JSON.parse(JSON.stringify(this.msgData));
              } else {
              }
              setTimeout(() => {
                self.currentSendMessage = false;
              }, 1000);
            })
            .catch((error) => {
              setTimeout(() => {
                self.currentSendMessage = false;
              }, 1000);
              alert(error?.response.data.message);

            });

          setTimeout(() => {
            const element = document.getElementById("chat__body");
            element.scrollTop = element.scrollHeight;
          }, 0);

        } else {

        }
      }
      this.isLoadingMedia = false;
      // if (filePromises && filePromises.length) {
      //   await this.handleSendMediaMessage(filePromises);
      // } else {
      //   alert("Have an Error while send Media");

      // }
    },

    async handleSendMediaMessage(dataIds) {
      const paramsNew = new URLSearchParams();
      paramsNew.append("chat_room_id", this.dataRoom.chat_room_id._id);
      paramsNew.append("chat_content", "");
      paramsNew.append("parent_id", "0");
      paramsNew.append("media_data", JSON.stringify(dataIds));

      const configNew = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Authorization": this.userData.auth_code,
        },
      };
      this.currentSendMessage = true;
      let self = this;
      axios
        .post(
          process.env.VUE_APP_CHAT_URL + "/chat-history/create",
          paramsNew,
          configNew
        )
        .then((response) => {
          if (response && response.data) {
            let dataToAdd = {
              ...response.data,
              ...{
                createBy: {
                  user_id: this.userData._id,
                },
              },
            };
            this.reloadMediaProp++;
            this.reloadAudioProp++;
            this.pushMsgData(dataToAdd);
          } else {
          }
          this.isLoadingMedia = false;
          setTimeout(() => {
            self.currentSendMessage = false;
          }, 1000);
        })
        .catch((error) => {
          this.isLoadingMedia = false;
          alert(error?.response.data.message);
          setTimeout(() => {
            self.currentSendMessage = false;
          }, 1000);
        });
    },

    async handleCreatePresignUrl(file) {

      var formData = new FormData();
      formData.append("files[]", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-Authorization": this.userData.auth_code,
        },
      };
      let dataReturn = await axios
        .post(
          process.env.VUE_APP_MEDIA_URL + "/upload-media",
          formData,
          config
        )
        .then((response) => {
          if (response && response.data) {
            return response.data;
          } else {
            return null;
          }
        })
        .catch((error) => {
          alert(error?.response?.data?.message);
          return null;
        });
      return dataReturn;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.chat {
  width: 100%;
}

.body_open_left {
  width: 360px;
  position: fixed;
  height: 100vh;
  background-color: #fff;
  border-right: 1px #efefef solid;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1000001;
}

.body_open_right {
  width: 300px;
  position: fixed;
  height: 100vh;
  background-color: #fff;
  border-right: 1px #efefef solid;
  top: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 60px;
  z-index: 1000001;
}

.body_open_background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 1000000;
}

.spinner-border.text-light {
  margin-top: 50vh !important;
}

.chat__header__avatar {
  width: 50px;
  height: 40px;
  float: left;
  position: relative
}

.chat__header__avatar__status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #00afb9;
  border: 3px #fff solid;
  position: absolute;
  bottom: -2px;
  right: 6px;
}

.chat_expired {
  padding: 30px 20px;
  color: #f08080;
  font-size: 14px;
  font-weight: 900;
  position: fixed;
  bottom: 0;
  border-top: 1px #efefef solid;
  width: calc(100% - 361px - 301px);
  left: 360px;
  height: 72px;
  background: #FDEDED;

  @include breakpoint(phablet) {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.btn-outline-primary {
  padding: 22px
}

.chat__header__avatar svg {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-top: 14px;
  cursor: pointer;
  user-select: none;
}

.typing_class {
  padding: 0 30px 10px 30px;
  font-size: 12px;
  color: #aaa;
  position: absolute;
  bottom: 70px;
  position: fixed;
  width: calc(100% - 361px - 301px);
  left: 360px;

  @include breakpoint(mobile) {
    width: 100%;
    left: 0;
    right: 0;
  }
}

.chat__header__avatar img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.chat__header__left {
  width: calc(100% - 60px);
  float: left;
  padding: 0 16px;
  font-weight: 900;
}

a {
  color: #444;
}

.chat__header__left__title {
  font-size: 15px;
  font-weight: 900;
  margin-top: -3px;
  width: 100%;
  float: left;

  a {
    color: #444;
  }
}

.chat__header__left__description {
  width: 100%;
  float: left;
  font-size: 13px;
  color: #aaa;
  font-weight: 400;
}

.chat__header {
  background: #ffffff;
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
  position: fixed;
  width: calc(100% - 361px - 301px);
  left: 360px;
  height: 70px;
  z-index: 1000;
  border-bottom: 1px #efefef solid;

  @include breakpoint(phablet) {
    width: 100%;
    left: 0;
    right: 0;
  }

  &__bg {
    width: 100%;
    height: 70px;
    float: left;
  }
}

.chat__header__greetings {
  width: 100%;
  padding: 0 0;
  color: #292929;
  float: left;

  @include breakpoint(phablet) {
    width: calc(100% - 60px);
    padding: 0 20px;
  }
}

.body_chat_info {
  display: none;

  @include breakpoint(phablet) {
    display: block;
    width: 30px;
    height: 30px;
    float: right;
    margin-top: 0px;

    svg {
      width: 30px;
      height: 30px;
      fill: #aaa;
    }
  }
}

.body_chat_left {
  width: 360px;
  float: left;
  position: relative;
  height: 100vh;
  background-color: #fff;
  border-right: 1px #efefef solid;

  @include breakpoint(phablet) {
    display: none;
  }
}

.body_chat_center {
  width: calc(100% - 662px);
  background: #fafafa;
  height: 100vh;
  float: left;
  position: relative;

  @include breakpoint(phablet) {
    width: 100%;
  }
}

.body_chat_menu {
  display: none;

  @include breakpoint(phablet) {
    display: block;
    width: 20px;
    height: 20px;
    float: left;
    margin-top: 5px;

    svg {
      fill: #5E0D8D;
    }
  }
}

.body_chat_right {
  width: 300px;
  float: left;
  border-left: 1px #efefef solid;
  background: #fafafa;
  height: 100vh;

  @include breakpoint(phablet) {
    display: none;
  }
}
</style>
