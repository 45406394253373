<template>
  <div class="chat">
    <div class="body_chat_left">
      <group-chat-category-list :chatId="''" />
    </div>
    <div class="body_chat_right">
      <div class="body_chat_right__image">
      </div>
      <div class="body_chat_right__title">
        Choose a chat to start
      </div>
    </div>
  </div>
</template>

<script>
import GroupChatCategoryList from "../components/GroupChatCategoryList.vue";

export default {
  data() {
    return {};
  },
  components: {
    GroupChatCategoryList
  },
  computed: {},
  created() {
    if (!localStorage.getItem("user")) {
      this.$router.push({
        name: "login",
      });
    }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.chat {
  justify-content: space-between;
  background: #fafafa;
}

.body_chat_left {
  width: 360px;
  float: left;
  height: 100vh;
  position: relative;
  background-color: #fff;
  border-right: 1px #efefef solid;

  @include breakpoint(phablet) {
    width: 100%;
    border-right: none;
  }
}

.body_chat_right {
  width: calc(100% - 360px);
  float: left;
  background: #fafafa;
  height: 100vh;
  padding: 20px;
  text-align: center;

  &__image {
    width: 300px;
    margin: 0 auto;
    margin-top: calc(50vh - 200px);

    lottie-player {
      width: 300px;
    }
  }

  &__title {
    width: 100%;
    margin-top: -20px;
    font-size: 22px;
    font-weight: 700;
    color: #444;
  }

  @include breakpoint(phablet) {
    display: none;
  }
}
</style>
