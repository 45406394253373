<template>
  <div id="app">
    <router-view class="vue-container" />
  </div>
</template>

<script>
export default {
  created() {
    let dataUser = localStorage.getItem("user");
    if (dataUser) {
      try {
        let dataUserObject = JSON.parse(dataUser);
        let currentPath = this.$route.name;
        if (currentPath === "login") {
          this.$router.push({
            name: "list",
            params: { userData: dataUserObject },
          });
        }
      } catch (error) {
      }
    }
  },
  methods: {
    moveToHome() {
      this.$router.push({ name: "login" });
      this.$router.go(this.$router.currentRoute);
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/_global";
@font-face {
  font-family: "Golden Plains - Demo";
  src: url("./assets/GoldenPlains.woff2") format("woff2"),
    url("./assets/GoldenPlains.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
</style>
