var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat"},[(_vm.isOpenLeftMenu)?_c('div',{staticClass:"body_open_left"},[(_vm.dataRoom.chat_room_id)?_c('chat-category-list',{attrs:{"chatId":_vm.dataRoom.chat_room_id}}):_vm._e()],1):_vm._e(),(_vm.isOpenRightMenu)?_c('div',{staticClass:"body_open_right"},[(_vm.dataRoom && _vm.dataRoom.chat_room_id)?_c('div',[_c('chat-detail',{attrs:{"dataRoom":_vm.dataRoom,"reloadMediaProp":_vm.reloadMediaProp,"reloadAudioProp":_vm.reloadAudioProp,"reloadRoomMember":_vm.reloadRoomMember,"roomId":_vm.dataRoom.chat_room_id,"roomTitle":_vm.dataAdvisor && _vm.dataAdvisor.display_name
          ? _vm.dataAdvisor.display_name
          : null,"userID":(_vm.dataAdvisor && _vm.dataAdvisor._id)
      ? _vm.dataAdvisor._id
      : 'LOL'}})],1):_vm._e()]):_vm._e(),(_vm.isOpenLeftMenu || _vm.isOpenRightMenu)?_c('div',{staticClass:"body_open_background",on:{"click":function () {
        _vm.isOpenLeftMenu = false;
        _vm.isOpenRightMenu = false;
      }}}):_vm._e(),_c('div',{staticClass:"body_chat_left"},[_c('chat-category-list',{attrs:{"chatId":_vm.chatId}})],1),_c('div',{staticClass:"body_chat_center"},[_c('div',{staticClass:"chat__header"},[_c('div',{staticClass:"body_chat_menu",on:{"click":function () {
            _vm.isOpenLeftMenu = true;
          }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 448 512"}},[_c('path',{attrs:{"d":"M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"}})])]),_c('span',{staticClass:"chat__header__greetings"},[_c('div',{staticClass:"chat__header__avatar"},[_c('img',{attrs:{"src":_vm.dataAdvisor && _vm.dataAdvisor.user_avatar
                ? _vm.dataAdvisor.user_avatar
                : 'https://i.stack.imgur.com/l60Hf.png'},on:{"error":_vm.replaceByDefault}}),(Number(_vm.dataAdvisor && _vm.dataAdvisor.user_active))?_c('div',{staticClass:"chat__header__avatar__status"}):_vm._e()]),_c('div',{staticClass:"chat__header__left"},[_c('div',{staticClass:"chat__header__left__title"},[_vm._v(" "+_vm._s(_vm.dataAdvisor && _vm.dataAdvisor.display_name ? _vm.dataAdvisor.display_name : null)+" ")]),(Number(_vm.dataAdvisor && _vm.dataAdvisor.user_active) === 0)?_c('div',{staticClass:"chat__header__left__description"},[_vm._v(" "+_vm._s(_vm.dataAdvisor && _vm.dataAdvisor.last_active ? "Offline - " + _vm.momentNew(_vm.dataAdvisor.last_active, "hh:mm DD/MM") : "User offline")+" ")]):_vm._e(),(Number(_vm.dataAdvisor && _vm.dataAdvisor.user_active) === 1)?_c('div',{staticClass:"chat__header__left__description"},[_vm._v(" "+_vm._s(_vm.dataAdvisor && _vm.dataAdvisor.last_active ? "Online - " + _vm.momentNew(_vm.dataAdvisor.last_active, "hh:mm DD/MM") : "User offline")+" ")]):_vm._e()])]),_c('div',{staticClass:"body_chat_info",on:{"click":function () {
            _vm.isOpenRightMenu = true;
          }}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 512 512"}},[_c('path',{attrs:{"d":"M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 24-24h16v-64H224c-13.25 0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z"}})])])]),_c('div',{staticClass:"chat__header__bg"}),_c('chat-list',{attrs:{"msgs":_vm.msgData,"userData":_vm.userData,"dataAdvisor":_vm.dataAdvisor}}),(_vm.isLoadingMedia)?_c('div',{staticStyle:{"padding":"0 0 10px 0","left":"0","right":"0","position":"absolute","bottom":"60px"}},[_vm._m(0)]):_vm._e(),(_vm.statusTyping)?_c('div',{staticClass:"typing_class"},[_vm._v(" "+_vm._s(_vm.messageTyping)+" ")]):_vm._e(),(_vm.dataRoom && _vm.handleCheckTime(_vm.dataRoom.end_time))?_c('div',{staticClass:"chat_expired"},[_vm._v(" Room is Expired. Can't send message! ")]):_vm._e(),(_vm.dataRoom && !_vm.handleCheckTime(_vm.dataRoom.end_time))?_c('div',[_c('chat-form',{attrs:{"statusTyping":_vm.statusTyping,"dataRoom":_vm.dataRoom},on:{"handleSubmitFile":_vm.handleSubmitFile,"submitMessage":_vm.sendMessage,"sendEmitTyping":_vm.sendEmitTyping}})],1):_vm._e(),_c('router-view')],1),_c('div',{staticClass:"body_chat_right"},[(_vm.dataRoom && _vm.dataRoom.chat_room_id)?_c('div',[_c('chat-detail',{attrs:{"dataRoom":_vm.dataRoom,"reloadMediaProp":_vm.reloadMediaProp,"reloadAudioProp":_vm.reloadAudioProp,"reloadRoomMember":_vm.reloadRoomMember,"roomId":_vm.chatId,"roomTitle":_vm.dataAdvisor && _vm.dataAdvisor.display_name
              ? _vm.dataAdvisor.display_name
              : null,"userID":(_vm.dataAdvisor && _vm.dataAdvisor._id)
  ? _vm.dataAdvisor._id
  : null}})],1):_vm._e()]),(_vm.isLoading)?_c('div',{staticClass:"background-border",staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"spinner-border text-light",attrs:{"role":"status"}})]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress",staticStyle:{"height":"8px","opacity":"0.5","border-radius":"0"}},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated",staticStyle:{"width":"100%"},attrs:{"role":"progressbar","aria-valuenow":"100","aria-valuemin":"0","aria-valuemax":"100"}})])}]

export { render, staticRenderFns }