<template>
  <div>
    <div class="chat__header">
      <span class="chat__header__greetings">
        <div class="chat__header__heading">
          <h2>
            <a href="/">
              <div class="header__logo__block">
                <img src="../assets/images/frame.png" />
              </div>
            </a> Messages
          </h2>
        </div>
        <div class="chat__header__heading">
          <h2>
            <button class=" btn btn-outline-primary w-100 chat_detail__media__body__button"
              @click="$router.push('/list')">
              Personal Chat
            </button>
          </h2>
        </div>

        <!-- <button class="chat__create__new" v-on:click="handleNewChat" v-if="userData.user_role === 'admin'">
          <svg viewBox="0 0 512 512">
            <path
              d="M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z" />
          </svg>
        </button> -->

        <div class="input_search__block">
          <svg viewBox="0 0 512 512">
            <path
              d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
          </svg>

          <input type="email" class="form-control input_search__heading" id="exampleFormControlInput1"
            placeholder="Search..." v-model="searchText" />
        </div>

        <div class="select_search__block">

          <select class="form-control" tabindex="8" v-model="statusMessage" style="height: 50px;">
            <option value="">
              {{ "Status" }}
            </option>
            <option v-for="(typeItem, typeIndex) in messageStatus" :key="'birthday_' + typeIndex"
              :value="typeItem.value">
              {{ typeItem.label }}
            </option>
          </select>
        </div>

      </span>
    </div>


    <div class="chat_category" id="chat__body_list_category">
      <group-chat-category :dataList="dataList" :chatIdActive="chatId" :isReloadList="isReloadList"
        :userData="userData">
      </group-chat-category>
      <div class="text-center w-100 mt-4 mb-4" v-if="isLoading">
        <div class="spinner-border text-info mb-4 mt-4" role="status"></div>
      </div>
      <div class="chat_empty_list" v-if="(dataList.length === 0) && !isLoading">
        <div class="chat_empty_list__image">
          <img src="../assets/images/empty_chat_list.png" />
        </div>
        <div class="chat_empty_list__title">
          <font class="font_heading">Chat is Empty.</font> <br />Find <a href="/advisor">an Advisor</a> and start Chat!
        </div>

      </div>

    </div>


    <div :class="(isListChat) ? 'chat_bottom is_list_chat' : 'chat_bottom'">
      <div class="chat_bottom_avatar">
        <a href="/user/dashboard">
          <img :src="
            userData && userData.user_avatar
              ? userData.user_avatar
              : '/chat/images/user_default.png'
          " />
        </a>
      </div>
      <div class="chat_bottom_title">
        <div class="chat_bottom_title__name">
          <a href="/user/dashboard">{{ userData.display_name }}</a>
        </div>
        <div class="chat_bottom_title__email">
          {{ userData.user_email ? userData.user_email : userData.user_login }}
        </div>
      </div>
      <div class="chat_bottom_logout" v-on:click="handleLogout()">LOGOUT</div>
    </div>

    <div class="background-border" v-if="statusPopupUser" v-on:click="handleClosePopup"></div>
    <div class="background-border" style="text-align: center" v-if="isLoading">
      <div class="spinner-border text-light" role="status"></div>
    </div>
    <div class="modal fade" id="exampleModalCenter" tabindex="1" role="dialog" aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true" style="display: block; opacity: 1" v-if="statusPopupUser">
      <div class="modal-dialog modal-dialog-centered modal_add_user" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 style="font-weight: 700" class="modal-title" id="exampleModalLongTitle">
              Choose room to send message
            </h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="
              () => {
                statusPopupUser = false;
              }
            ">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="input_search__block">
              <svg viewBox="0 0 512 512">
                <path
                  d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
              </svg>

              <input type="email" class="form-control input_search__heading" id="exampleFormControlInput1"
                v-model="searchEmail" placeholder="Search by E-mail..." />

              <div class="spinner-border text-light" style="
                  text-align: center;
                  margin-top: 40px;
                  margin-bottom: 40px;
                  margin-left: auto;
                  margin-right: auto;
                " role="status" v-if="loadingSearch"></div>
              <div class="search_empty" v-if="dataUser.length === 0 && !loadingSearch">
                Not have user data!
              </div>

              <div class="list_body_user">
                <ul class="list-group">

                  <li class="list-group-item" v-for="(item, index) in dataUser" :key="'data_user_' + index"
                    v-on:click="handleCreateNewRoom(item)">
                    <div class="chat_choose_avatar">
                      <img :src="
                        item && item.user_avatar
                          ? item.user_avatar
                          : '/chat/images/user_default.png'
                      " @error="replaceByDefault" />
                    </div>

                    <div class="chat_choose__left">
                      {{ item.display_name }}<br />
                      <p style="font-size: 13px; color: #aaa; margin-bottom: 0">
                        {{ item.display_name }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import GroupChatCategory from "@/components/GroupChatCategory";
import Constant from "@/Constant";
import axios from "axios";
import { loginService } from "../services/login.services";
import EventBus from '../eventbus/event-bus';
import { json } from "body-parser";

export default {
  data() {
    let currentParam = this.$route.name;
    return {
      userData: null,
      dataList: [],
      dataUser: [],
      firstDataList: [],
      // dataSubscription: [],
      statusPopupUser: false,
      isLoading: false,
      searchEmail: "",
      loadingSearch: false,
      searchTimeout: null,
      isReloadList: 0,
      searchText: "",
      statusMessage: "",
      searchStatus: "",
      isFirstGetRoomList: true,
      stopLoadingRoom: false,
      pageRoom: 1,
      isListChat: (currentParam === 'list'),
      messageStatus: [
        { label: "Read", value: "read" },
        { label: "Unread", value: "unread" },
      ]
    };
  },
  props: ["chatId", "roomId",],
  components: {
    GroupChatCategory,
  },
  computed: {},

  // mounted() {
  //   EventBus.$on(payLoad => {
  //     this.msg = payLoad;
  //   });
  // },

  created() {
    this.pageRoom = 1;
    this.dataList = [];
    this.firstDataList = [];
    this.handleUserData();
  },
  sockets: {
    msgToUser(message) {
      this.isReloadList++;
    }
  },

  watch: {
    chatId() {
      this.isReloadList++;
    },
    "statusMessage"(newData) {
      this.searchStatus = newData
      this.handleGetRoomChat(this.userData, 1, newData)
    },
    "$route.name": {
      handler(newValue) {
        if (newValue === 'list') {
          this.isListChat = true;
        } else {
          this.isListChat = false;
        }
      }
    },
    searchText(newData) {

      if (newData) {
        let keyword = newData.toLowerCase();

        this.dataList = this.firstDataList.filter((item) => {
          console.log(item)

          let textSearch = item.chat_room_id.room_name.toLowerCase();
          return textSearch.indexOf(keyword) > -1;
        });
      } else {
        this.dataList = this.firstDataList;
      }
    },
    isReloadList(newData) {
      if (newData) {
        this.pageRoom = 1;
        //this.dataList = [];
        //this.firstDataList = [];
        this.handleGetRoomChat(this.userData, 1, this.searchStatus);
      }
    },
    userData(newData) {
      this.pageRoom = 1;
      this.dataList = [];
      this.firstDataList = [];
      this.handleGetRoomChat(newData, 1);
      //this.handleSearchText("");
    },
    searchEmail(newData) {
      clearTimeout(this.searchTimeout);
      let self = this;
      this.searchTimeout = setTimeout(() => {
        //Process Text
        //self.handleSearchText(newData);
      }, 800);
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = '/chat/images/user_default.png';
    },
    ...mapMutations({
      pushMsgData: Constant.PUSH_MSG_DATA,
    }),
    handleScroll() {
      var self = this;
      if (
        self.$route.path.indexOf("chat") === -1 &&
        self.$route.path.indexOf("list") === -1
      ) {
        window.removeEventListener("scroll", self.handleScroll, true);
      }
      if (!self.isLoading && !self.stopLoadingRoom) {
        var objDiv = document.getElementById("chat__body_list_category");
        let scrollTop = objDiv.scrollTop;
        if (objDiv.scrollHeight - scrollTop <= 700) {
          let newPageRoom = this.pageRoom + 1;
          self.handleGetRoomChat(this.userData, newPageRoom);
          this.pageRoom = newPageRoom;
        }
      }
    },
    handleLogout() {
      loginService.logout(this.userData.auth_code);
      localStorage.removeItem("user");
      window.location.href = "/";
    },
    handleSearchText(text) {
      this.loadingSearch = true;
      let self = this;

      let userToken = this.userData.auth_code;
      const config = {
        headers: {
          "X-Authorization": userToken,
        },
      };
      let urlSearch =
        process.env.VUE_APP_CHAT_URL +
        "/chat-user/list?page=1&limit=10&search=" +
        text;
      axios
        .get(urlSearch, config)
        .then((response) => {
          if (response && response.data) {
            this.dataUser = response.data;
          }
          self.loadingSearch = false;
        })
        .catch((error) => {
          self.loadingSearch = false;
          alert(error.response.data.message);
        });
    },
    handleClosePopup() {
      this.statusPopupUser = false;
    },
    handleNewChat() {
      this.statusPopupUser = true;
    },
    handleCreateNewRoom(userObject) {
      this.statusPopupUser = false;
      this.isLoading = true;

      const params = new URLSearchParams();
      params.append("advisor_id", userObject.user_id);
      params.append("duration", 60);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Authorization": this.userData.auth_code,
        },
      };
      axios
        .post(
          process.env.VUE_APP_CHAT_URL + "/chat-room/create",
          params,
          config
        )
        .then((response) => {
          if (response && response.data) {
            this.isLoading = false;
            this.$router.push({ path: "/chat/" + response.data.chat_room_id });
            this.isReloadList++;
          } else {
            alert("Create new have an Error. Please try again!");
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.statusPopupUser = false;
          this.isLoading = false;
          alert(error.response.data.message);
        });
    },
    handleGetRoomChat(userObject, page, status) {
      const config = {
        headers: {
          "X-Authorization": userObject.auth_code,
        },
      };
      var self = this;
      this.isLoading = true;
      if (status) {
        axios
          .get(
            process.env.VUE_APP_CHAT_URL +
            "/chat-room/list?room_type=group&limit=20&page=" +
            page + "&read_count=" + status,
            config
          )
          .then((response) => {
            if (response && response.data) {
              if (page > 1) {
                this.dataList = [...this.dataList, ...response.data];
                this.firstDataList = [...this.firstDataList, ...response.data];
              } else {
                this.dataList = response.data
                this.firstDataList = response.data
              }

              if (response.data.length < 20) {
                this.stopLoadingRoom = true;
              }
              if (this.isFirstGetRoomList) {
                setTimeout(() => {
                  let dataChat = document.getElementById(
                    "chat__body_list_category"
                  );
                  if (dataChat) {
                    dataChat.addEventListener("scroll", self.handleScroll, true);
                  }
                }, 500);
              }
              this.isFirstGetRoomList = false;
            } else {
              this.stopLoadingRoom = true;
            }
            this.isLoading = false;
          })
          .catch((error) => {
            if (this.isFirstGetRoomList) {
              setTimeout(() => {
                let dataChat = document.getElementById(
                  "chat__body_list_category"
                );
                if (dataChat) {
                  dataChat.addEventListener("scroll", self.handleScroll, true);
                }
              }, 500);
            }
            this.isLoading = false;
            this.stopLoadingRoom = true;
            this.isFirstGetRoomList = false;
          });
      } else {
        axios
          .get(
            process.env.VUE_APP_CHAT_URL +
            "/chat-room/list?room_type=group&limit=20&page=" +
            page,
            config
          )
          .then((response) => {
            if (response && response.data) {
              if (page > 1) {
                this.dataList = [...this.dataList, ...response.data];
                this.firstDataList = [...this.firstDataList, ...response.data];
              } else {
                this.dataList = response.data
                this.firstDataList = response.data
              }

              if (response.data.length < 20) {
                this.stopLoadingRoom = true;
              }
              if (this.isFirstGetRoomList) {
                setTimeout(() => {
                  let dataChat = document.getElementById(
                    "chat__body_list_category"
                  );
                  if (dataChat) {
                    dataChat.addEventListener("scroll", self.handleScroll, true);
                  }
                }, 500);
              }
              this.isFirstGetRoomList = false;
            } else {
              this.stopLoadingRoom = true;
            }
            this.isLoading = false;

            // this.handleGetSubscription(userObject.auth_code, response.data);


          })
          .catch((error) => {
            if (this.isFirstGetRoomList) {
              setTimeout(() => {
                let dataChat = document.getElementById(
                  "chat__body_list_category"
                );
                if (dataChat) {
                  dataChat.addEventListener("scroll", self.handleScroll, true);
                }
              }, 500);
            }
            this.isLoading = false;
            this.stopLoadingRoom = true;
            this.isFirstGetRoomList = false;
          });
      }
    },

    handleUserData() {
      let dataUser = localStorage.getItem("user");
      if (dataUser) {
        try {
          let dataUserObject = JSON.parse(dataUser);
          this.userData = dataUserObject;
        } catch (error) {
          this.userData = this.$route.params.userData;
        }
      } else {
        this.userData = this.$route.params.userData;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/_variables.scss";

.chat_empty_list {
  width: 100%;
  padding: 20px;
  text-align: center;

  &__image {
    img {
      width: 150px;
      margin: 0 auto;
      margin-top: calc(50% - 40px);
      margin-bottom: 20px;
    }
  }

  &__title {
    text-align: center;
    width: 100%;
    float: left;

    a {
      text-decoration: none;
    }

    font {
      font-size: 20px;
      font-weight: 700
    }
  }
}

.list_body_user {
  width: 100%;
  float: left;
  margin-top: 20px;
  text-align: left;
}

.header__logo__block {
  width: 40px;
  height: 40px;
  // background-color: #5E0D8D;
  border-radius: 8px;
  float: left;
  margin-right: 8px;

}

.header__logo__block img {
  width: 30px;
  height: 30px;
  margin: 3px 5px 4px 5px;
}

.spinner-border.text-light {
  margin-top: 50vh !important;
}

.list-group li {
  cursor: pointer;
  user-select: none;
}

.chat_choose_avatar img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 4px;
}

.chat_choose_avatar {
  width: 60px;
  float: left;
}

.chat_choose__left {
  width: calc(100% - 60px);
  float: left;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.chat__header {
  padding: 20px;
  font-size: 16px;
  font-weight: 700;
}

.chat_category {
  height: calc(100vh - 216px);
  overflow-y: scroll;
  margin-top: 20px;
  float: left;
  width: 100%;
}

.chat_bottom {
  height: 73px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-top: 1px #efefef solid;
  bottom: 0;
  background-color: #fff;
  padding: 10px 20px;
  position: fixed;
  width: 359px;
  left: 0;

  @include breakpoint(phablet) {
    width: 359px;

    &.is_list_chat {
      width: 100%;
    }
  }
}

.chat_bottom_avatar {
  width: 40px;
  height: 40px;
  float: left;
}

.chat_bottom_avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.chat_bottom_title {
  width: calc(100% - 40px - 60px);
  float: left;
  padding-left: 16px;
}

.chat_bottom_title__name {
  font-size: 16px;
  font-weight: 700;
  color: #444;
  width: 100%;
  float: left;

  a {
    color: #444;
    text-decoration: none;
  }
}

.chat_bottom_logout {
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
  font-size: 10px;
  color: #fff;
  margin-top: 6px;
  background-color: #f4a261;
  border-radius: 8px;
  user-select: none;
  float: left;
  cursor: pointer;
}

.chat_bottom_title__email {
  color: #aaa;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  float: left;
  margin-top: -2px;
  padding-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chat__header__greetings {
  color: #292929;
}

.chat__header__heading {
  width: calc(100% - 30px);
  float: left;

  @include breakpoint(phablet) {
    h2 {
      line-height: 40px;
    }

    img {
      line-height: unset;
      margin: 0px 5px 4px 5px;
    }
  }
}

.chat__create__new {
  width: 30px;
  height: 30px;
  float: right;
  font-size: 15px;
  font-weight: 900;
  margin-top: 2px;
  border-radius: 2px;
  border: none;
  user-select: none;
  cursor: pointer;
  float: right;
  background-color: unset;
}

.input_search__heading {
  background-color: #f5f5f5;
  border: 1px #f5f5f5 solid;
  padding: 12px 0.75rem 12px 40px;
  float: left;
}

.chat__create__new svg {
  width: 20px;
  height: 20px;
  margin: 5px;
  fill: #5E0D8D;
}

.input_search__block {
  width: 60%;
  margin-top: 16px;
  float: left;
  position: relative;
}

.input_search__block svg {
  position: absolute;
  top: 15px;
  left: 15px;
  fill: #ccc;
  width: 18px;
  height: 18px;
}

.select_search__block {
  width: 35%;
  margin-top: 16px;
  float: right;
  position: relative;
}

.modal-dialog,
.modal-content {
  /* 80% of window height */
  height: 80%;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.modal-content {
  border: 1px #efefef solid !important;
  border-radius: 16px !important;
}

.modal-body {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}
</style>
