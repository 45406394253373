<template>
  <div :id="'chat_message_id_' + msg.chat_history_id">
    <div class="chat_yourmesage__time" v-if="!isSame">
      <div class="chat_yourmesage__time_text">
        <div v-if="isToDay(momentNew(msg.createdAt, 'DD-MM-YYYY'))">
          {{ momentNew(msg.createdAt, "hh:mm:ss") }}
        </div>
        <div v-if="!isToDay(momentNew(msg.createdAt, 'DD-MM-YYYY'))">
          {{ momentNew(msg.createdAt, "hh:mm:ss DD/MM/YYYY") }}
        </div>
        <div v-for="(item, index) in msg.media_ids" :key="'chat_image_l' + index">
          <div v-if="item && item.media_type == 'system_message'" v-for="(i, e) in item.media_meta">
            <div>
              {{ JSON.stringify(i.value) }}
            </div>
          </div>
        </div>

      </div>
    </div>

    <div v-if="(msg.createBy._id) === (userData._id)" class="chat__mymessage" :class="[isSame ? '' : 'chat__first']">
      <p class="chat__mymessage__paragraph" v-if="msg.chat_content">
        {{ msg.chat_content }}
      </p>

      <div class="chat__mymessage__paragraph" v-if="
        (msg && msg.media_ids && msg.media_ids.length)
      ">

        <div class="chat__mymessage__image" v-for="(item, index) in msg.media_ids" :key="'chat_image_l' + index">
          <img :src="item.media_url" @error="replaceByDefault"
            v-if="item && item.media_url && item.media_type == 'image'"
            v-on:click="handleOpenLightbox(item.media_url)" />
          <div class="chat__message__default_play" v-if="item && item.media_url && item.media_type == 'video'"
            v-on:click="handleOpenVideo(item.media_url)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

              <path
                d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
            </svg>
          </div>
          <div class="chat__message__default_play" style="background-color: #f2dca6 !important"
            v-if="item && item.media_url && item.media_type == 'audio'" v-on:click="handleOpenAudio(item.media_url)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

              <path
                d="M256 32C112.9 32 4.563 151.1 0 288v104C0 405.3 10.75 416 23.1 416S48 405.3 48 392V288c0-114.7 93.34-207.8 208-207.8C370.7 80.2 464 173.3 464 288v104C464 405.3 474.7 416 488 416S512 405.3 512 392V287.1C507.4 151.1 399.1 32 256 32zM160 288L144 288c-35.34 0-64 28.7-64 64.13v63.75C80 451.3 108.7 480 144 480L160 480c17.66 0 32-14.34 32-32.05v-127.9C192 302.3 177.7 288 160 288zM368 288L352 288c-17.66 0-32 14.32-32 32.04v127.9c0 17.7 14.34 32.05 32 32.05L368 480c35.34 0 64-28.7 64-64.13v-63.75C432 316.7 403.3 288 368 288z" />
            </svg>
          </div>
          <div class="chat__message__default_play" v-if="item && item.media_url && item.media_type == 'audio_call'">
            <a href="/">
              <div class="header__logo__block">
                <img style="width: 40px" src="../assets/images/icons8-callback-50.png" />
              </div>
            </a> Call audio
            <br />
            0 second
          </div>

          <div class="chat__message__default_play" v-if="item && item.media_url && item.media_type == 'video_call'">
            <a href="/">
              <div class="header__logo__block">
                <img style="width: 40px" src="../assets/images/icons8-callback-50.png" />
              </div>
            </a> Call video
            <br />
            0 second
          </div>

        </div>

      </div>
    </div>

    <div v-else class="chat__yourmessage" :class="[isSame ? '' : 'chat__first']">
      <div class="chat__yourmessage__avartar">
        <img :src="avatar" alt="Chat message" v-if="!isSame" class="chat__yourmessage__img" @error="replaceByDefault" />
        <div class="chat__yourmessage__img" v-if="isSame"></div>
      </div>
      <div>
        <div class="chat__yourmessage__user" v-if="!isSame">
          {{ msg.createBy.display_name }}
        </div>
        <div class="chat__yourmessage__p">
          <p class="chat__yourmessage__paragraph" v-if="msg.chat_content">
            {{ msg.chat_content }}
          </p>

          <div class="chat__yourmessage__paragraph" v-if="
            (msg && msg.media_ids && msg.media_ids.length)
          ">

            <div class="chat__mymessage__image" v-for="(item, index) in msg.media_ids" :key="'chat_image_l' + index">
              <img :src="item.media_url" @error="replaceByDefault"
                v-if="item && item.media_url && item.media_type == 'image'"
                v-on:click="handleOpenLightbox(item.media_url)" />

              <div class="chat__message__default_play" v-if="item && item.media_url && item.media_type == 'video'"
                v-on:click="handleOpenVideo(item.media_url)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

                  <path
                    d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM176 168V344C176 352.7 180.7 360.7 188.3 364.9C195.8 369.2 205.1 369 212.5 364.5L356.5 276.5C363.6 272.1 368 264.4 368 256C368 247.6 363.6 239.9 356.5 235.5L212.5 147.5C205.1 142.1 195.8 142.8 188.3 147.1C180.7 151.3 176 159.3 176 168V168z" />
                </svg>
              </div>

              <div class="chat__message__default_play" style="background-color: #f2dca6 !important"
                v-if="item && item.media_url && item.media_type == 'audio'"
                v-on:click="handleOpenAudio(item.media_url)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">

                  <path
                    d="M256 32C112.9 32 4.563 151.1 0 288v104C0 405.3 10.75 416 23.1 416S48 405.3 48 392V288c0-114.7 93.34-207.8 208-207.8C370.7 80.2 464 173.3 464 288v104C464 405.3 474.7 416 488 416S512 405.3 512 392V287.1C507.4 151.1 399.1 32 256 32zM160 288L144 288c-35.34 0-64 28.7-64 64.13v63.75C80 451.3 108.7 480 144 480L160 480c17.66 0 32-14.34 32-32.05v-127.9C192 302.3 177.7 288 160 288zM368 288L352 288c-17.66 0-32 14.32-32 32.04v127.9c0 17.7 14.34 32.05 32 32.05L368 480c35.34 0 64-28.7 64-64.13v-63.75C432 316.7 403.3 288 368 288z" />
                </svg>
              </div>

              <div class="chat__message__default_play" v-if="item && item.media_url && item.media_type == 'audio_call'">
                <a href="/">
                  <div class="header__logo__block">
                    <img style="width: 40px" src="../assets/images/icons8-callback-50.png" />
                  </div>
                </a> Call audio
                <br />
                0 second
              </div>

              <div class="chat__message__default_play" v-if="item && item.media_url && item.media_type == 'video_call'">
                <a href="/">
                  <div class="header__logo__block">
                    <img style="width: 40px" src="../assets/images/icons8-callback-50.png" />
                  </div>
                </a> Call video
                <br />
                0 second
              </div>

              <div class="chat__message__default_message" v-if="item && item.media_type == 'system_message'"
                v-for="(i, e) in item.media_meta">
                <div>
                  {{ JSON.stringify(i.value) }}
                </div>
              </div>

            </div>


          </div>

        </div>
      </div>
    </div>
    <div class="chat__body__status right" v-if="msg.createBy._id === userData._id && isSame">
      {{ msg.chat_status === "send" ? "Đã gửi" : "Đang gửi..." }}
    </div>
    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>

    <div class="chat_preview_background" v-if="videoModal">
      <button type="button" title="Close" class="chat_preview_background__close" v-on:click="closeVideo()"><svg
          xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z">
          </path>
        </svg></button>

      <div class="chat_preview_background__click" v-on:click="closeVideo()">

      </div>
      <div class="chat_preview_background__block">
        <video crossorigin playsinline id="player">
          <!-- Video files -->
          <source :src="videoSource" type="video/mp4" />
        </video>
      </div>

    </div>

    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style="display: block; opacity: 1" v-if="audioModal">
      <div class="modal-dialog modal-dialog-centered" role="document" style="height: auto">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLongTitle">Audio</h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" v-on:click="
                () => {
                  audioModal = false;
                  audioSource = '';
                }
              ">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <audio controls style="width: 100%">
              <source :src="audioSource" />
            </audio>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="
  () => {
    audioModal = false;
    audioSource = '';
  }
            ">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import moment from "moment";
import Plyr from 'plyr';

export default {
  props: ["msg", "prev", "userData"],
  data() {
    return {
      isSame: false,
      avatar: require("../assets/avatar.svg"),
      items: [],
      index: null,
      audioModal: false,
      audioSource: "",
      videoSource: "",
      videoModal: false,
    };
  },
  components: {
    CoolLightBox,
  },
  methods: {
    /**
     * @author Tony Vu
     * @function playVideo
     */
    playVideo() {
      var self = this;
      setTimeout(function () {
        const player = new Plyr('#player', {
          title: 'Open video',
          autoplay: true,
          invertTime: false,
          tooltips: { controls: true, seek: true },
          fullscreen: {
            enabled: true,
            fallback: true,
            iosNative: true
          },
          loading: true,
          controls: [
            'play',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
            'settings',
            'fullscreen',
            'play-large',
            'progress',
            'pip',
            'airplay'
          ],
          i18n: {
            qualityBadge: {
              2160: '4K',
              1440: '2K',
              1080: 'HD',
              720: 'HD',
              576: 'SD',
              480: 'SD',
            },
          },
        });
      }, 100);

    },
    closeVideo() {
      this.videoModal = false;
      this.videoSource = '';
    },
    replaceByDefault(e) {
      e.target.src = '/chat/images/user_default.png';
    },
    handleOpenLightbox(url) {
      let dataToSet = [url];
      this.items = dataToSet;
      this.index = 0;
    },

    // handleOpenLightbox(mediaUrl) {

    //   let userData = this.userData;
    //   let userToken = userData.auth_code;
    //   this.currentSelectMedia = mediaUrl;
    // },

    handleOpenAudio(url) {
      this.audioModal = true;
      this.audioSource = url;
    },
    handleOpenVideo(url) {
      this.videoModal = true;
      this.videoSource = url;
      this.playVideo();
    },
    handleGetArray(imageString) {
      let imageArray = [];
      try {
        imageArray = JSON.parse(imageString);
      } catch (error) {
      }
      return imageArray;
    },
    isSamePerson(msg, prev) {
      if (prev === null) {
        return false;
      } else if (
        prev[0]?.createBy?.display_name == msg?.createBy?.display_name
      ) {
        return true;
      } else {
        return false;
      }
    },
    isToDay(date) {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = dd + "-" + mm + "-" + yyyy;
      return date === today;
    },
    handleCheckTime(stopTime) {
      let stopTimeNumber = new Date(stopTime).getTime();
      if (stopTimeNumber - this.currentTime <= 0) {
        return true;
      } else {
        return false;
      }
    },
    momentNew(dateTime, format) {
      return moment(dateTime).format(format);
    },
  },
  created() {
    this.isSame = this.isSamePerson(this.msg, this.prev);
    if (this.msg?.createBy?.user_avatar) {
      this.avatar = this.msg?.createBy?.user_avatar;
    }
  },
};
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";

.chat__message__default_play {
  // background-color: #9ee5dd;
  width: 100%;
  height: 100%;
}

.chat__message__default_play svg {
  width: 40px;
  height: 40px;
  margin: 10px;
  fill: #fff;
}


.header__logo__block {
  width: 50px;
  height: auto;
  // background-color: #5E0D8D;
  border-radius: 8px;
  float: left;
  margin-right: 8px;

}


.chat__mymessage {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin: 0;
  min-height: 40px;
  line-break: unset;
}

.chat__body__status {
  width: 100%;
  font-size: 10px;
  color: #aaa;
}

.chat__body__status.right {
  text-align: right;
}

.chat__mymessage__paragraph {
  max-width: 400px;
  margin: 8px 0 0 40px;
  border-radius: 20px 20px 0px 20px;
  background-color: #5E0D8D;
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
  word-break: break-all;
  word-break: break-word;
  text-align: left;

  @include breakpoint(mobile) {
    max-width: 100%;
  }
}


.chat__yourmessage {
  display: flex;
}

.chat__yourmessage__avartar {
  width: 40px;
  margin-right: 1rem;
}

.chat__yourmessage__img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.chat_yourmesage__time {
  width: 100%;
  text-align: center;
}

.chat_yourmesage__time_text {
  width: 100%;
  max-width: 300px;
  display: inline-block;
  text-align: center;
  padding: 16px 16px 8px 16px;
  border-bottom: 1px #efefef solid;
  color: #aaa;
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 12px;
  height: 27px;

  div {
    background: #fafafa;
    display: inline-block;
    padding: 0 16px;
    line-height: 22px;
  }
}

.chat__mymessage__image {
  width: 200px;
  float: left;
  margin: 4px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
}

.chat__mymessage__image img {
  width: 200px;
  height: auto;
  object-fit: cover;
}

.chat__mymessage__video {
  width: 200px;
  height: 112px;
  float: left;
  margin: 4px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
}

.chat__mymessage__video svg {
  width: 60px;
  height: 60px;
  margin: 26px 70px;
  object-fit: cover;

  &:hover {
    fill: $primaryColor;
  }
}

.chat__mymessage__audio {
  width: 100px;
  height: 100px;
  float: left;
  margin: 4px;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
}

.chat__mymessage__audio svg {
  width: 50px;
  height: 50px;
  margin: 25px;
  object-fit: cover;

  &:hover {
    fill: $primaryColor;
  }
}

.chat__yourmessage__user {
  font-size: 14px;
  font-weight: 700;
  color: #292929;
  margin-top: 0;
  margin-block-end: 0rem;
  text-align: left;
}

.chat__yourmessage__p {
  display: flex;
  align-items: flex-end;
  line-break: unset;
}

.chat__yourmessage__paragraph {
  margin: 4px 40px 8px 0px;
  border-radius: 0px 20px 20px 20px;
  background-color: #f3f3f3;
  max-width: 400px;
  color: #414141;
  padding: 0.8rem;
  font-size: 14px;
  word-break: break-all;
  word-break: break-word;
  text-align: left;

  @include breakpoint(mobile) {
    max-width: 100%;
  }

}

.chat__yourmessage__paragraph:has(.chat__message__default_message) {
  display: none;
}


// .chat__message__default_message {
//   display: none;
// }


// .chat__mymessage__video {
//   width: 100%;
//   float: left;
// }

// .chat__mymessage__video video {
//   border-radius: 20px;
//   max-width: 400px;
//   width: 100%;
// }

.chat__yourmessage__time {
  margin: 0;
  font-size: 12px;
  color: #9c9c9c;
  text-align: right;
}
</style>
